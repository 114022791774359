import React, { Component } from "react";
import SearchIcon from "../common/searchIcon";

class Search extends Component {
  render() {
    return (
      <>
        <form className="ml-5">
          <div
            className="input-group input-group-sm input-group-solid"
            style={{ maxWidth: "250px" }}
          >
            <input
              name="first_name"
              id="first_name"
              className="form-control"
              placeholder="Keyword search"
              autoComplete="off"
              value={this.props.keyword}
              onChange={(e) => {
                this.props.onSearch(e.currentTarget.value);
              }}
            />
            <SearchIcon />
          </div>
        </form>
        <form className="ml-5">
          <div
            className="input-group input-group-sm input-group-solid"
            style={{ maxWidth: "195px" }}
            onClick={this.props.reset}
          >
            <a href="javascript:void (0)">
              <span
                className="text-dark-50 font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                Reset
              </span>
            </a>
          </div>
        </form>
      </>
    );
  }
}

export default Search;
