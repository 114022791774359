import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "request",
  initialState: {
    requests: [],
    pagination: {},
    admin: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    requestRequested: (request, action) => {
      request.loading = true;
    },
    requestReceived: (request, action) => {
      request.requests = action.payload.data;
      request.pagination = action.payload.pagination;
      request.loading = false;
      request.lastFetch = Date.now();
    },
    requestRequestFailed: (request, action) => {
      request.loading = false;
    },
  },
});

export const { requestRequested, requestReceived, requestRequestFailed } =
  slice.actions;
export default slice.reducer;

// Action Creators
const url = "redeemStarRequests/";

export const loadRequest = (params) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: requestRequested.type,
      onSuccess: requestReceived.type,
      onError: requestRequestFailed.type,
    })
  );
};

export const updateRequest = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "changeStatus/" + id,
      method: "PUT",
      data,
      callback,
    })
  );
};

export const getRequest = createSelector(
  (state) => state.entities.request,
  (request) => request
);
