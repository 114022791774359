import React, { Component } from "react";
import user from "../../include/media/default-profile.png";

export default class UserProfile extends Component {
  logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/";
  };

  render() {
    const { firstName, lastName, email, userType } = JSON.parse(
      localStorage.getItem("admin_details")
    );
    return (
      <div
        id="kt_quick_user"
        className={
          this.props.userShow === true
            ? "offcanvas offcanvas-left p-10 offcanvas-on"
            : "offcanvas offcanvas-left p-10"
        }
      >
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">User Profile</h3>
          <a
            href="javascript:void (0)"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={this.props.toggleShowUser}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
        <hr />
        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
              <div
                className="symbol-label"
                style={{ backgroundImage: `url(${user})` }}
              ></div>
            </div>
            <div className="d-flex flex-column">
              <a
                href="javascript:void (0)"
                className="font-weight-bold font-size-h5 text-333 text-hover-primary"
              >
                {firstName + " " + lastName}
              </a>
              <a
                href="javascript:void (0)"
                className="font-weight-bold text-333 text-hover-primary"
              >
                <span className="navi-link p-0 pb-2"></span>
                {email}
              </a>
              <div className="text-333 mt-1">
                {userType === "superadmin" ? "Master Admin" : "Admin"}
              </div>
              <div className="navi mt-2">
                <a
                  href="javascript:void (0)"
                  onClick={this.logout}
                  className="btn btn-sm btn-primary font-weight-bolder py-2 btn-sm"
                >
                  Sign Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
