import React, { Component } from "react";
import helpIcon from "../../../include/media/help-circle.svg";
import ReactTooltip from "react-tooltip";

class Password extends Component {
  render() {
    const { name, label, error, ...rest } = this.props;

    return (
      <>
        <ReactTooltip
          style={{
            boxShadow: "0px 5px 30px rgb(0 0 0 / 10%)",
            borderRadius: "4px",
          }}
          backgroundColor={"white"}
          textColor={"black"}
          border={true}
          borderColor="grey"
          id="pass-info"
          html={true}
        />
        <div className="form-group row fv-plugins-icon-container has-danger">
          <div className="col-lg-12">
            <div className="d-flex align-items-center">
              <label htmlFor={name}>{label}</label>
              <a
                tabindex="0"
                class="help-popover ml-2 cursor-pointer"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-tip="Password should be between 8 - 16 characters long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
                data-original-title=""
                title=""
                data-for="pass-info"
                style={{ marginBottom: "0.5rem" }}
              >
                <img style={{ width: "15px" }} src={helpIcon} alt="" />
              </a>
            </div>

            <input
              name={name}
              className="form-control"
              type="password"
              ref={this.input}
              {...rest}
            />
            {error && (
              <div className="fv-plugins-message-container">
                <div data-validator="notEmpty" className="fv-help-block">
                  {error}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Password;
