import React from "react";
import { Helmet } from "react-helmet";

class HelmetComponent extends React.Component {
  render() {
    var defaultTitle = "Gamtred | Admin Panel";

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{defaultTitle}</title>
        <meta
          name="description"
          content="We offer a tailor-made game-based training solution for companies to increase effectivity in workforce training"
        />
      </Helmet>
    );
  }
}

export default HelmetComponent;
