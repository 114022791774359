import React, { Component } from "react";
import SideBar from "../common/sideBar";
import HelmetComponent from "../common/helmetComponent";
import UserProfile from "../common/userProfile";
import "../../include/css/dev.css";
import { getUsers, loaduserAnalytics } from "../../store/users";
import { connect } from "react-redux";
import Moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const initialState = {
  userShow: false,
  asside: false,
  data: {
    title: "",
    description: "",
    answerType: "",
  },
  images: {},
  isMandatory: false,
  isPublished: false,
  isExternalTraining: false,
  delete_id: "",
  asside3: false,
  modal: false,
  modalQues: false,
  asside2: false,
  isShow: false,
  questionModal: false,
  photoStatus: "Add file",
  displayImage: "",
  editShow: false,
  id: "",
  editData: null,
  editing: false,
  errors: {},
  sectionId: "",
  questionId: "",
  editingQuestion: false,
  editDataQuestion: null,
  userData: "",
  loading: false,
  maxStar: 0,
  maxTrainingsAttended: 0,
  totalPercentage: 0,
};

export class UserAnalytics extends Component {
  state = initialState;

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.loaduserAnalytics(
      {
        page: "1",
        sort: "createdAt",
        order: "desc",
        user: this.props.location.state && this.props.location.state.id,
      },
      (res) => {
        this.setState({ loading: false });
      }
    );
  };

  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  toggleDelQues = (sectionId, questionId) => {
    this.setState({
      modalQues: !this.state.modalQues,
      sectionId,
      questionId,
    });
  };

  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggleAddQuestion = (id) => {
    this.setState({
      sectionId: id,
      questionModal: !this.state.questionModal,
    });
  };

  toggleEditQuestion = (sectionId, questionId, data) => {
    this.setState({
      editingQuestion: true,
      editDataQuestion: data,
      sectionId,
      questionId,
      questionModal: !this.state.questionModal,
    });
  };

  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleEditUser = (e, data) => {
    if (e) e.preventDefault();
    this.setState({
      isShow: !this.state.isShow,
      editing: true,
      editData: data,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.loading && this.state.loading !== prevState.loading) {
      this.starsColl();
    }
  };

  starsColl = () => {
    var stars = [];
    var totalCorrectQuestions = [];
    var b = [];
    var c = [];
    var sum = 0;
    var per = 0;
    const data = this.props.userAnalytics.userAnalytics[0];
    data &&
      data.trainingEvaluations.map((tr) => {
        for (let i = 0; i < tr.attempts.length; i++) {
          b.push(tr.attempts[i].starsCollected);
          c.push(
            +parseFloat(
              (tr.attempts[i].totalCorrectQuestions /
                tr.attempts[i].totalQuestions) *
                100
            ).toFixed(1)
          );
        }
        stars.push(b);
        totalCorrectQuestions.push(c);

        b = [];
        c = [];
      });

    stars.map((a) => {
      sum = sum + Math.max(...a);
    });
    totalCorrectQuestions.map((a) => {
      per = per + Math.max(...a);
    });

    let percantage = (per / totalCorrectQuestions.length).toFixed(1);

    this.setState({
      maxStar: sum,
      totalPercentage: percantage,
    });
    this.setState({
      maxTrainingsAttended: data.trainingEvaluations
        ? data.trainingEvaluations.length
        : 0,
    });
  };

  render() {
    const data = this.props.userAnalytics.userAnalytics[0];
    const loading = this.state.loading;

    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="User Analytics" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div
                  className="subheader py-3 py-lg-4 subheader-transparent"
                  id="kt_subheader"
                >
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h2
                          className="d-flex align-items-center text-dark font-weight-bold my-1 mr-3"
                          style={{ fontSize: "1.25rem", fontWeight: "500" }}
                        >
                          User Analytics
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div
                      className="card card-custom card-sticky"
                      id="kt_page_sticky_card"
                    >
                      <div
                        className="card-header"
                        style={{
                          "z-index": "90",
                          top: "-1px",
                          left: "125px",
                          right: "25px",
                        }}
                      >
                        <div className="card-title">
                          <h3 className="card-label">
                            {loading ? (
                              <Skeleton width={100} />
                            ) : data &&
                              data.fullName &&
                              data.fullName.length > 0 ? (
                              data.fullName
                            ) : (
                              data && data.mobile && data.mobile
                            )}
                            <i className="mr-2"></i>
                          </h3>
                          <div
                            style={{
                              border: "",
                              display: "flex",
                              marginLeft: "5rem",
                            }}
                          >
                            {
                              <>
                                {/* <h4 style={{ marginLeft: "5rem" }}>
                                  Time Spent on App :{" "}
                                  {loading ? (
                                    "--"
                                  ) : (
                                    <>
                                      {data && data.totalTimeSpent
                                        ? parseFloat(
                                            data.totalTimeSpent / (1000 * 60)
                                          ).toFixed(1)
                                        : "0 "}
                                      {data &&
                                      data.totalTimeSpent &&
                                      parseFloat(
                                        data.totalTimeSpent / (1000 * 60)
                                      ).toFixed(1) > 1
                                        ? " mins"
                                        : " min"}
                                    </>
                                  )}
                                </h4> */}
                                {loading ? (
                                  <>
                                    <div>
                                      <Skeleton width={100} />
                                    </div>
                                    <div className="ml-3">
                                      <Skeleton width={100} />
                                    </div>
                                  </>
                                ) : (
                                  <h4 style={{ marginLeft: "5rem" }}>
                                    Total Stars: {this.state.maxStar}
                                  </h4>
                                )}
                                {loading ? (
                                  <>
                                    <div className="ml-5">
                                      <Skeleton width={100} />
                                    </div>
                                    <div className="ml-3">
                                      <Skeleton width={100} />
                                    </div>
                                  </>
                                ) : (
                                  <h4 style={{ marginLeft: "5rem" }}>
                                    Total Number of Trainings :{" "}
                                    {this.state.maxTrainingsAttended}
                                  </h4>
                                )}
                                {loading ? (
                                  <>
                                    <div className="ml-5">
                                      <Skeleton width={100} />
                                    </div>
                                    <div className="ml-3 mr-5">
                                      <Skeleton width={100} />
                                    </div>
                                  </>
                                ) : (
                                  <h4 style={{ marginLeft: "5rem" }}>
                                    Success Rate (Best Attempts) :{" "}
                                    {!isNaN(this.state.totalPercentage) &&
                                    this.state.totalPercentage
                                      ? this.state.totalPercentage
                                      : "0"}
                                    %
                                  </h4>
                                )}
                              </>
                            }

                            {/* {loading ? (
                              <Skeleton width={100} />
                            ) : (
                              <>
                                <h4 style={{ marginLeft: "5rem" }}>
                                  App Opened :{" "}
                                  {loading ? (
                                    "--"
                                  ) : (
                                    <>
                                      {data && data.appOppendCount
                                        ? data.appOppendCount
                                        : " 0"}
                                      {data && data.appOppendCount > 1
                                        ? " times"
                                        : " time"}
                                    </>
                                  )}
                                </h4>
                              </>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div action="" id="training">
                          {loading ? (
                            [1, 2].map((a) => (
                              <>
                                <div className="d-flex flex-column row mb-4">
                                  {/* <div > */}
                                  <div className="d-flex flex-column mb-4">
                                    <Skeleton width={200} height={15} />

                                    <Skeleton width={400} height={15} />
                                  </div>

                                  <div className="d-flex flex-column ">
                                    <Skeleton width={200} height={15} />

                                    <Skeleton width={400} height={15} />
                                  </div>
                                </div>
                                {[1, 2, 3, 4].map((at, i) => (
                                  <>
                                    <div className="row">
                                      <div className="d-flex flex-column mb-5 ">
                                        <div className="d-flex col col-12">
                                          <div>
                                            <Skeleton width={200} height={15} />
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: "40px",
                                              marginRight: "230px",
                                            }}
                                          >
                                            <Skeleton width={350} height={15} />
                                          </div>
                                          <div>
                                            <Skeleton width={200} height={15} />
                                          </div>
                                          <div style={{ marginLeft: "40px" }}>
                                            <Skeleton width={350} height={15} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </>
                            ))
                          ) : data &&
                            data.trainingEvaluations &&
                            data.trainingEvaluations.length > 0 ? (
                            data.trainingEvaluations.map((tr, i) => (
                              <>
                                <div className="row mb-4">
                                  <h6
                                    className="col col-12"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Training Title
                                  </h6>

                                  <h6 className="col col-12  ">
                                    {tr.training && tr.training.name
                                      ? tr.training.name
                                      : "--"}
                                  </h6>

                                  <h6
                                    className="col col-12 mt-4"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Training Description
                                  </h6>

                                  <h6 className="col col-12">
                                    {tr.training && tr.training.description
                                      ? tr.training.description
                                      : "--"}
                                  </h6>
                                </div>

                                {tr.attempts &&
                                  tr.attempts.length > 0 &&
                                  tr.attempts.map((at, i) => (
                                    <>
                                      <div className="row mb-3">
                                        <h6
                                          className="col col-6"
                                          style={{ fontWeight: "700" }}
                                        >
                                          Attempt : {i + 1}
                                        </h6>
                                      </div>
                                      <div className="row">
                                        <h6 className="col col-2">
                                          Total Questions
                                        </h6>
                                        <h6 className="col col-4">
                                          {at.totalQuestions
                                            ? at.totalQuestions
                                            : "-"}
                                        </h6>
                                        <h6 className="col col-2">Stars</h6>
                                        <h6 className="col col-4">
                                          {at.starsCollected
                                            ? at.starsCollected
                                            : "0"}
                                        </h6>
                                      </div>
                                      <div className="row">
                                        <h6 className="col col-2">
                                          Correct Answers
                                        </h6>
                                        <h6 className="col col-4">
                                          {at.totalCorrectQuestions
                                            ? at.totalCorrectQuestions
                                            : "-"}
                                        </h6>
                                        <h6 className="col col-2">Quit</h6>
                                        <h6 className="col col-4">
                                          {at.isQuit === false ? "No" : "Yes"}
                                        </h6>
                                      </div>
                                      <div className="row">
                                        <h6 className="col col-2">
                                          Incorrect Answers
                                        </h6>
                                        <h6 className="col col-4">
                                          {at.totalQuestions &&
                                            at.totalCorrectQuestions &&
                                            at.totalQuestions -
                                              at.totalCorrectQuestions}
                                        </h6>
                                        <h6 className="col col-2">Date</h6>
                                        <h6 className="col col-4">
                                          {at.createdAt &&
                                            Moment(at.createdAt).format("LL")}
                                        </h6>
                                      </div>
                                      <div className="row mb-5">
                                        <h6 className="col col-2">% Marks</h6>
                                        <h6 className="col col-4">
                                          {at.totalCorrectQuestions &&
                                          at.totalQuestions &&
                                          parseFloat(
                                            (at.totalCorrectQuestions /
                                              at.totalQuestions) *
                                              100
                                          ) === 100
                                            ? 100
                                            : parseFloat(
                                                (at.totalCorrectQuestions /
                                                  at.totalQuestions) *
                                                  100
                                              ).toFixed(1)}
                                          %
                                        </h6>
                                      </div>
                                    </>
                                  ))}
                                <hr />
                              </>
                            ))
                          ) : (
                            <h3>No data to display</h3>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserProfile
          userShow={this.state.userShow}
          toggleShowUser={this.toggleShowUser}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userAnalytics: getUsers(state),
  users: getUsers(state).users[0],
});

const mapDispatchToProps = (dispatch) => ({
  loaduserAnalytics: (params, callback) =>
    dispatch(loaduserAnalytics(params, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserAnalytics);
