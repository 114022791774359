import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import { connect } from "react-redux";
import HelmetComponent from "../common/helmetComponent";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  getAssessment,
  loadAllAssessment,
  loadTrainingAnalytics,
  deletedTraining,
  updateTraining,
} from "../../store/assessment";
import AddAssessment from "./addAssessment";
import { deleted, failed } from "../common/misc";
import AssessmentGrid from "./assessmentGrid";
import SortingIcon from "../common/sortingIcon";
import Pagination from "../common/Pagination";
import SortingIconUp from "../common/sortingIconUp";
import AssignUser from "./assignUser";
import AssignedUser from "./assignedUser";
import { debounce } from "lodash";
import Search from "./search";

class Assessment extends Component {
  state = {
    loading: false,
    usersAttendedShow: false,
    userAttendedId: "",
    userShow: false,
    modal: false,
    active: false,
    admin_id: "",
    status: "",
    msg: "",
    delete_id: "",
    asside: false,
    isShow: false,
    asside2: false,
    asside3: false,
    sort: "createdAt",
    order: "desc",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    assignModal: false,
    assignedModal: false,
    assignedData: null,
    trainings: "",
    trainingsName: "",
    trainingId: "",
    keyword: "",
    assignedUser: [],
  };

  handleSearch = debounce(() => {
    this.props.loadAllAssessment({
      page: "1",
      sort: this.state.sort,
      order: this.state.order,
      keyword: this.state.keyword,
    });
  }, 300);

  onSearch = (value) => {
    this.setState(
      {
        keyword: value,
      },
      () => this.handleSearch()
    );
  };

  reset = () => {
    this.setState(
      {
        keyword: "",
      },
      () => this.handleSearch()
    );
  };

  toggleAssignedFlyer = (e, assignedData, trainingId) => {
    if (e) e.preventDefault();
    this.setState({
      assignedModal: !this.state.assignedModal,
      assignedData,
      trainingId,
    });
  };

  toggleAssignFlyer = () => {
    this.setState({
      assignModal: !this.state.assignModal,
    });
  };

  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  toggleUsersAttendedShow = (id) => {
    this.setState({
      usersAttendedShow: !this.state.usersAttendedShow,
      userAttendedId: id,
    });
  };

  deletedAssessment = (id) => {
    this.props.deletedTraining(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAssessment({
        page: this.state.currentPage,
      });
      this.setState({
        modal: !this.state.modal,
      });
      // toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadAllAssessment({
        page: this.state.currentPage,
      });
    }
  };

  sortByTitle = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "name",
        order: "asc",
      });
    else {
      this.setState({
        sort: "name",
        order: "desc",
      });
    }
  };
  sortByCreatedOn = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "createdAt",
        order: "asc",
      });
    else {
      this.setState({
        sort: "createdAt",
        order: "desc",
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort !== prevState.sort ||
      this.state.order !== prevState.order
    ) {
      this.props.loadAllAssessment({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
      });
    }
    if (
      this.state.usersAttendedShow !== prevState.usersAttendedShow &&
      this.state.usersAttendedShow
    ) {
      this.setState({ loading: true });
      this.props.loadTrainingAnalytics(
        {
          page: "1",
          training: this.state.userAttendedId,
        },
        () => this.setState({ loading: false })
      );
    }
  };
  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadAllAssessment({
        page: data.page,
      });
    }
  };
  handlePublished = (id, isPublished) => {
    const payLoad = {
      isPublished,
    };
    this.props.updateTraining(id, payLoad, this.callbackUpdate);
  };

  handleExternalTraining = (id, isExternalTraining) => {
    const payLoad = {
      isExternalTraining,
    };
    this.props.updateTraining(id, payLoad, this.callbackUpdate);
  };

  callbackUpdate = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAssessment({
        page: this.state.currentPage,
      });

      // toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    const { assessment, pagination, trainingAnalytics } = this.props;
    const { total_record, current_page, record_per_page, total_page } =
      pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Trainings Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Trainings
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          Total : {total_record} records
                        </span>
                        <Search
                          keyword={this.state.keyword}
                          onSearch={this.onSearch}
                          reset={this.reset}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void (0)"
                        className="btn font-weight-bold btn-sm font-size-base ml-2 new-button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            this.state.trainings &&
                            this.state.trainings.length > 0
                          ) {
                            this.toggleAssignFlyer();
                          } else {
                            toast(
                              <AlertError
                                message={"Please select a training first."}
                              />
                            );
                          }
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Assign/Un-assign Users
                      </a>
                      <a
                        href="javascript:void (0)"
                        className="btn btn-primary font-weight-bold btn-sm font-size-base ml-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddUser();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Training
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "3%" }}
                                >
                                  <span>Select</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "25%" }}
                                >
                                  <span>
                                    Title
                                    {this.state.sort === "name" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={this.sortByTitle} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByTitle}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "7%" }}
                                >
                                  <span>Mandatory</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "7%" }}
                                >
                                  <span>Published</span>
                                </th>
                                {/* <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "10%" }}
                                >
                                  <span>External Training</span>
                                </th> */}
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "7%" }}
                                >
                                  <span>Assigned users</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "8%" }}
                                >
                                  <span>Users Attempted</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "14%" }}
                                >
                                  <span>
                                    created <br />
                                    By
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "8%" }}
                                >
                                  <span>
                                    Created <br /> On
                                    {this.state.sort === "createdAt" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreatedOn}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreatedOn}
                                      />
                                    )}
                                  </span>
                                </th>

                                <th
                                  className="datatable-cell datatable-cell-sort action-padding-adjust "
                                  style={{ width: "12%" }}
                                >
                                  <span>Action</span>
                                </th>
                              </tr>
                            </thead>
                            <AssessmentGrid
                              delete_id={this.state.delete_id}
                              modal={this.state.modal}
                              toggle={this.toggle}
                              assessment={assessment}
                              deleted={this.deletedAssessment}
                              toggleEditUser={this.toggleEditUser}
                              history={this.props.history}
                              trainings={this.state.trainings}
                              trainingsName={this.state.trainingsName}
                              setTrainings={(trainings) => {
                                this.setState({ trainings: trainings });
                              }}
                              setTrainingsName={(trainingsName) => {
                                this.setState({ trainingsName });
                              }}
                              toggleAssignedFlyer={this.toggleAssignedFlyer}
                              setAssignedUser={(assignedUser) =>
                                this.setState({ assignedUser })
                              }
                              handlePublished={this.handlePublished}
                              handleExternalTraining={
                                this.handleExternalTraining
                              }
                              toggleUsersAttendedShow={
                                this.toggleUsersAttendedShow
                              }
                              usersAttendedShow={this.state.usersAttendedShow}
                              userAttendedId={this.state.userAttendedId}
                              trainingAnalytics={trainingAnalytics}
                              loading={this.state.loading}
                            />
                          </table>
                          <Pagination
                            totalRecords={total_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AddAssessment
            isShow={this.state.isShow}
            toggleAddUser={this.toggleAddUser}
            initialPage={this.state.currentPage}
          />
          <AssignUser
            assignModal={this.state.assignModal}
            toggleAssignFlyer={this.toggleAssignFlyer}
            trainings={this.state.trainings}
            trainingsName={this.state.trainingsName}
            assignedUser={this.state.assignedUser}
            setTrainings={(trainings) => {
              this.setState({ trainings });
            }}
            setTrainingsName={(trainingsName) => {
              this.setState({ trainingsName });
            }}
            setAssignedUser={(assignedUser) => this.setState({ assignedUser })}
            intialPage={this.state.currentPage}
          />
          <AssignedUser
            assignedModal={this.state.assignedModal}
            toggleAssignedFlyer={this.toggleAssignedFlyer}
            assignedData={this.state.assignedData}
            trainingId={this.state.trainingId}
            initialPage={this.state.currentPage}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  assessment: getAssessment(state).assessment,
  pagination: getAssessment(state).pagination,
  trainingAnalytics: getAssessment(state).trainingAnalytics[0],
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAssessment: (param) => dispatch(loadAllAssessment(param)),
  deletedTraining: (id, callback) => dispatch(deletedTraining(id, callback)),
  updateTraining: (id, data, callback) =>
    dispatch(updateTraining(id, data, callback)),
  loadTrainingAnalytics: (params, callback) =>
    dispatch(loadTrainingAnalytics(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
