import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "users",
  initialState: {
    users: [],
    userAnalytics: [],
    usersAssign: [],
    pagination: {},
    update: {},
    add: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    userRequested: (user, action) => {
      user.loading = true;
    },

    userReceived: (user, action) => {
      user.users = action.payload.data;
      user.pagination = action.payload.pagination;
      user.loading = false;
      user.lastFetch = Date.now();
    },

    userRequestFailed: (user, action) => {
      user.loading = false;
    },

    userAssignRequested: (user, action) => {
      user.loading = true;
    },

    userAssignReceived: (user, action) => {
      user.usersAssign = action.payload.data;
      user.loading = false;
      user.lastFetch = Date.now();
    },

    userAssignRequestFailed: (user, action) => {
      user.loading = false;
    },

    userAnalyticRequested: (user, action) => {
      user.loading = true;
    },

    userAnalyticReceived: (user, action) => {
      user.userAnalytics = action.payload.data;
      user.loading = false;
    },

    userAnalyticRequestFailed: (user, action) => {
      user.loading = false;
    },

    updatedUser: (user, action) => {
      user.update = action.payload;
      user.loading = false;
      user.lastFetch = Date.now();
    },

    updatedUserRequestFailed: (user, action) => {
      user.loading = false;
    },
    userAdded: (user, action) => {
      user.add = action.payload;
      user.loading = false;
      user.lastFetch = Date.now();
    },

    userAddRequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

export const {
  userRequested,
  userReceived,
  userRequestFailed,
  userAnalyticRequested,
  userAnalyticReceived,
  userAnalyticRequestFailed,
  userAssignRequested,
  userAssignReceived,
  userAssignRequestFailed,
  updatedUser,
  updatedUserRequestFailed,
  userAdded,
  userAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "organizations/allUsers/";
const urlDelete = "organizations/deleteUser/";
const urlAnalytics = "organizations/userAnalytics/";
const urlExternalUser = "organizations/addExternalUser/";

export const loadAllUsers = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: userRequested.type,
      onSuccess: userReceived.type,
      onError: userRequestFailed.type,
    })
  );
};

export const loadAllUsersAssign = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: userAssignRequested.type,
      onSuccess: userAssignReceived.type,
      onError: userAssignRequestFailed.type,
    })
  );
};

export const loaduserAnalytics = (params, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlAnalytics,
      params,
      callback,
      onStart: userAnalyticRequested.type,
      onSuccess: userAnalyticReceived.type,
      onError: userAnalyticRequestFailed.type,
    })
  );
};

export const updateUser = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "organizations/" + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedUser.type,
      onError: updatedUserRequestFailed.type,
    })
  );
};
export const addUser = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "organizations/addUser",
      method: "post",
      data,
      onSuccess: userAdded.type,
      onError: userAddRequestFailed.type,
    })
  );
};

export const deleteUser = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlDelete + id,
      method: "delete",
      callback,
    })
  );
};

export const changePassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "auth/changePassword",
      method: "post",
      data,
    })
  );
};

export const resetPassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "auth/resetPassword",
      method: "post",
      data,
    })
  );
};

export const addExternalUser = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: urlExternalUser,
      method: "post",
      data,
    })
  );
};

export const getUsers = createSelector(
  (state) => state.entities.users,
  (users) => users
);
