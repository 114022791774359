import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "administrator",
  initialState: {
    administrator: [],
    overallAnalytics: {},
    pagination: {},
    update: {},
    add: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    administratorRequested: (administrator, action) => {
      administrator.loading = true;
    },

    administratorReceived: (administrator, action) => {
      administrator.administrator = action.payload.data;
      administrator.pagination = action.payload.pagination;
      administrator.loading = false;
      administrator.lastFetch = Date.now();
    },

    administratorRequestFailed: (administrator, action) => {
      administrator.loading = false;
    },
    analyticsRequested: (administrator, action) => {
      administrator.loading = true;
    },

    analyticsReceived: (administrator, action) => {
      administrator.overallAnalytics = action.payload.data;
      administrator.loading = false;
    },

    analyticsRequestFailed: (administrator, action) => {
      administrator.loading = false;
    },

    updatedAdministrator: (administrator, action) => {
      administrator.update = action.payload;
      administrator.loading = false;
      administrator.lastFetch = Date.now();
    },

    updatedAdministratorRequestFailed: (administrator, action) => {
      administrator.loading = false;
    },
    administratorAdded: (administrator, action) => {
      administrator.add = action.payload;
      administrator.loading = false;
      administrator.lastFetch = Date.now();
    },

    administratorAddRequestFailed: (administrator, action) => {
      administrator.loading = false;
    },
  },
});

export const {
  administratorRequested,
  administratorReceived,
  administratorRequestFailed,
  analyticsRequested,
  analyticsReceived,
  analyticsRequestFailed,
  updatedAdministrator,
  updatedAdministratorRequestFailed,
  administratorAdded,
  administratorAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "organizations/allOrganizations/";
const urlAnalytics = "organizations/overallAnalytics/";
const urlDelete = "organizations/deleteOrganization/";

export const loadAllAdministrator = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: administratorRequested.type,
      onSuccess: administratorReceived.type,
      onError: administratorRequestFailed.type,
    })
  );
};

export const loadAnalytics = (params, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlAnalytics,
      params,
      callback,
      onStart: analyticsRequested.type,
      onSuccess: analyticsReceived.type,
      onError: analyticsRequestFailed.type,
    })
  );
};

export const updateAdministrator = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "organizations/" + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedAdministrator.type,
      onError: updatedAdministratorRequestFailed.type,
    })
  );
};
export const addAdministrator = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "organizations/addOrganization",
      method: "post",
      data,
      onSuccess: administratorAdded.type,
      onError: administratorAddRequestFailed.type,
    })
  );
};

export const deletedAdministrator = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlDelete + id,
      method: "delete",
      callback,
    })
  );
};

export const changePassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "auth/changePassword",
      method: "post",
      data,
    })
  );
};

export const resetPassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "auth/resetPassword",
      method: "post",
      data,
    })
  );
};

export const getAdministrator = createSelector(
  (state) => state.entities.administrator,
  (administrator) => administrator
);
