import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import closeBtn from "../../include/media/close.svg";
import Skeleton from "react-loading-skeleton";

export class TrainingInfo extends Component {
  render() {
    const data =
      this.props.userAnalytics[0] &&
      this.props.userAnalytics[0].trainingEvaluations;

    const userName =
      this.props.userAnalytics[0] && this.props.userAnalytics[0].fullName;

    const mobile =
      this.props.userAnalytics[0] && this.props.userAnalytics[0].mobile;

    return (
      <>
        <div
          className={
            this.props.show
              ? "offcanvas offcanvas3 offcanvas-right p-10 offcanvas-on "
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0"> Trainings Attended</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => this.props.onHide()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />

          {this.props.loading ? (
            <>
              <div className="mb-5">
                <h4 className="mb-4 text-bold-trainingName">User Info :</h4>
                <div className="d-flex ">
                  <div>
                    <Skeleton width={150} />
                  </div>
                  <div className="user-left-margin">
                    <Skeleton width={150} />
                  </div>
                </div>

                <div className="mb-5"></div>

                <hr />
              </div>
              <div className="mt-5">
                {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                  <>
                    <div className="mb-4">
                      <Skeleton width={400} />
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="mb-5">
                <h4 className="mb-4 text-bold-trainingName">User Info :</h4>
                <div className="d-flex ">
                  <h5>Name : {userName}</h5>
                  <h5 className="user-left-margin">
                    Mobile : {mobile ? mobile : "Not Available"}{" "}
                  </h5>
                </div>

                <div className="mb-5"></div>

                <hr />
              </div>
              <div className="mt-5">
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <div className="mb-4">
                      <h5>{item.training && item.training.name}</h5>
                      <div className="mb-5"></div>
                    </div>
                  ))
                ) : (
                  <h5>No training attempted by the user</h5>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default TrainingInfo;
