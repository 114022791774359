import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import { connect } from "react-redux";
import HelmetComponent from "../common/helmetComponent";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

import { deleted, failed } from "../common/misc";
import SortingIcon from "../common/sortingIcon";
import Pagination from "../common/Pagination";
import SortingIconUp from "../common/sortingIconUp";
import RequestGrid from "./requestGrid";
import { getRequest, loadRequest, updateRequest } from "../../store/request";
import Search from "./search";
import { debounce } from "lodash";

class RequestTable extends Component {
  state = {
    userShow: false,
    modal: false,
    active: false,
    req_id: "",
    status: "",
    msg: "",
    delete_id: "",
    asside: false,
    isShow: false,
    asside2: false,
    asside3: false,
    sort: "createdAt",
    order: "desc",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    editing: false,
    editData: null,
    keyword: "",
  };

  handleSearch = debounce(() => {
    this.props.loadRequest({
      sort: this.state.sort,
      order: this.state.order,
      page: "1",
      keyword: this.state.keyword,
    });
  }, 300);

  onSearch = (value) => {
    this.setState(
      {
        keyword: value,
      },
      () => this.handleSearch()
    );
  };

  reset = () => {
    this.setState(
      {
        keyword: "",
      },
      () => this.handleSearch()
    );
  };

  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      req_id: id,
      status: status,
      msg: msg,
    });
  };

  toggleAddShop = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleEditShop = (event, editData) => {
    if (event) event.preventDefault();
    this.setState({
      isShow: !this.state.isShow,
      editing: true,
      editData,
    });
  };

  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  UpdateRequest = () => {
    const formData = {
      status: this.state.status,
    };
    this.props.updateRequest(this.state.req_id, formData, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadRequest({
        sort: this.state.sort,
        order: this.state.order,
        page: this.state.currentPage,
      });
      this.setState({
        active: !this.state.active,
      });
      // toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadRequest({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
      });
    }
  };

  sortByTitle = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "name",
        order: "asc",
      });
    else {
      this.setState({
        sort: "name",
        order: "desc",
      });
    }
  };
  sortByCreatedOn = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "createdAt",
        order: "asc",
      });
    else {
      this.setState({
        sort: "createdAt",
        order: "desc",
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort !== prevState.sort ||
      this.state.order !== prevState.order
    ) {
      this.props.loadRequest({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
      });
    }
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadRequest({
        page: data.page,
        sort: this.state.sort,
        order: this.state.order,
      });
    }
  };

  render() {
    const requests = this.props.requests;
    const { total_record, current_page, record_per_page, total_page } =
      this.props.pagination;

    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Trainings Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Requests
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          Total : {total_record} records
                        </span>
                        <Search
                          keyword={this.state.keyword}
                          onSearch={this.onSearch}
                          reset={this.reset}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "30%" }}
                                >
                                  <span>
                                    Name
                                    {this.state.sort === "name" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={this.sortByTitle} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByTitle}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "20%" }}
                                >
                                  <span>Stars required</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "20%" }}
                                >
                                  <span>Status</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "20%" }}
                                >
                                  <span>created By</span>
                                </th>
                                <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "15%" }}
                                >
                                  <span>
                                    Created On
                                    {this.state.sort === "createdAt" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreatedOn}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreatedOn}
                                      />
                                    )}
                                  </span>
                                </th>

                                {/* <th
                                  className="datatable-cell datatable-cell-sort"
                                  style={{ width: "20%" }}
                                >
                                  <span>Action</span>
                                </th> */}
                              </tr>
                            </thead>
                            <RequestGrid
                              delete_id={this.state.delete_id}
                              modal={this.state.modal}
                              toggle={this.toggle}
                              requests={requests}
                              deleted={this.deletedProduct}
                              toggleEditShop={this.toggleEditShop}
                              history={this.props.history}
                              modalActive={this.state.active}
                              toggleAcitve={this.toggleAcitve}
                              msg={this.state.msg}
                              status_id={this.state.req_id}
                              Update={this.UpdateRequest}
                            />
                          </table>
                          <Pagination
                            totalRecords={total_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  requests: getRequest(state).requests,
  pagination: getRequest(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadRequest: (param) => dispatch(loadRequest(param)),
  updateRequest: (id, data, callback) =>
    dispatch(updateRequest(id, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestTable);
