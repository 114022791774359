import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import HelmetComponent from "../common/helmetComponent";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Joi from "joi-browser";
import Form from "../common/form/form";

import ModalPopup from "../common/modal";

import { informationSaved, failed, deleted } from "../common/misc";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import AddQuestion from "./addQuestion";
import {
  deleteQuestion,
  deleteSection,
  getAssessment,
  loadDetailAssessment,
  updateQuestion,
  updateTraining,
  uploadTrainingImage,
} from "../../store/assessment";
import _ from "lodash";
import AddSection from "./addSection";

const initialState = {
  userShow: false,
  asside: false,
  data: {
    title: "",
    description: "",
    answerType: "",
  },
  images: {},
  isMandatory: false,
  isPublished: false,
  isExternalTraining: false,
  delete_id: "",
  asside3: false,
  modal: false,
  modalQues: false,
  asside2: false,
  isShow: false,
  questionModal: false,
  photoStatus: "Add file",
  displayImage: "",
  editShow: false,
  id: "",
  editData: null,
  editing: false,
  errors: {},
  sectionId: "",
  questionId: "",
  editingQuestion: false,
  editDataQuestion: null,
};
class AssessmentForm extends Form {
  state = initialState;
  schema = {
    title: Joi.string()
      .required()
      .label("Training Title")
      .error(() => {
        return { message: "Title is required" };
      }),
    description: Joi.string()
      .required()
      .label("Training Title")
      .error(() => {
        return { message: "Description is required" };
      }),
    answerType: Joi.string().allow(""),
  };
  componentDidMount = () => {
    this.props.loadDetailAssessment(
      this.props.location.state && this.props.location.state.data._id
    );
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.detail !== prevProps.detail) {
      const data = { ...this.state.data };
      data.title = this.props.detail.name;
      data.description = this.props.detail.description;
      this.setState({
        data,
        images: this.props.detail.displayPicture,
        isMandatory: this.props.detail.isMendatory,
        isPublished: this.props.detail.isPublished,
        isExternalTraining: this.props.detail.isExternalTraining,
      });
    }
  };
  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  toggleDelQues = (sectionId, questionId) => {
    this.setState({
      modalQues: !this.state.modalQues,
      sectionId,
      questionId,
    });
  };

  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggleAddQuestion = (id) => {
    this.setState({
      sectionId: id,
      questionModal: !this.state.questionModal,
    });
  };

  toggleEditQuestion = (sectionId, questionId, data) => {
    this.setState({
      editingQuestion: true,
      editDataQuestion: data,
      sectionId,
      questionId,
      questionModal: !this.state.questionModal,
    });
  };

  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleEditUser = (e, data) => {
    if (e) e.preventDefault();
    this.setState({
      isShow: !this.state.isShow,
      editing: true,
      editData: data,
    });
  };

  handleDeleteSection = (id) => {
    const id1 = this.props.location.state && this.props.location.state.data._id;
    var data = {
      section: this.state.delete_id,
    };
    this.props.deleteSection(id1, data, this.callback);
  };

  handleDeleteQuestion = (id) => {
    const id1 = this.props.location.state && this.props.location.state.data._id;
    var data = {
      section: this.state.sectionId,
      question: this.state.questionId,
    };
    this.props.deleteQuestion(id1, data, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(
        this.props.location.state && this.props.location.state.data._id
      );
      this.setState({
        modal: false,
        modalQues: false,
      });
      // toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  doSubmit = () => {
    const { title, description } = this.state.data;
    const { errors, images } = this.state;
    if (_.isEmpty(images)) {
      errors.images = "Cover photo is required";
    } else {
      delete errors.images;
    }
    if (!_.isEmpty(errors)) {
      return this.setState({ errors });
    }
    const id = this.props.location.state && this.props.location.state.data._id;
    var formdata;
    formdata = {
      name: title,
      description,
      displayPicture: this.state.images,
      isMendatory: this.state.isMandatory,
      isPublished: this.state.isPublished,
      isExternalTraining: this.state.isExternalTraining,
    };

    this.props.updateTraining(id, formdata, this.editCallback);
  };

  editCallback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(
        this.props.location.state && this.props.location.state.data._id
      );
      //toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  uploadFile = async (e) => {
    if (e.target.files[0].size / 1024 / 1024 > 0.5) {
      toast(
        <AlertError message={"Image file size should not exceed 0.5 mb"} />
      );
    } else {
      this.setState({
        loading: true,
        browserLabel: "Uploading...",
        disable: true,
      });
      this.setState({ imageName: e.target.files[0].name });
      const file = e.target.files[0];
      const data = new FormData();
      data.append("image", file);

      this.props.uploadTrainingImage(data, (res) => {
        const { errors } = this.state;
        this.setState({ images: res.data.data });
        delete errors.images;
        this.setState({
          loading: false,
          errors,
          browserLabel: "Browse",
          disable: false,
        });
      });
    }
  };

  render() {
    const { detail } = this.props;
    var disabled = false;
    !_.isEmpty(detail) && detail.sections && detail.sections.length === 0
      ? (disabled = true)
      : !_.isEmpty(detail) &&
        detail.sections.map((sec) => {
          return sec.questions && sec.questions.length === 0
            ? (disabled = true)
            : (disabled = false);
        });

    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Training" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div
                  className="subheader py-3 py-lg-4 subheader-transparent"
                  id="kt_subheader"
                >
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h2
                          className="d-flex align-items-center text-dark font-weight-bold my-1 mr-3"
                          style={{ fontSize: "1.25rem", fontWeight: "500" }}
                        >
                          Manage Training
                        </h2>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void (0)"
                        className="btn  font-weight-bold btn-sm font-size-base ml-2 new-button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddUser();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add Section
                      </a>
                      <button
                        form="training"
                        type="submit"
                        className="btn btn-primary ml-2 font-weight-bold btn-sm font-size-base"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div
                      className="card card-custom card-sticky"
                      id="kt_page_sticky_card"
                    >
                      <div
                        className="card-header"
                        style={{
                          "z-index": "90",
                          top: "-1px",
                          left: "125px",
                          right: "25px",
                        }}
                      >
                        <div className="card-title">
                          <h3 className="card-label">
                            {detail.name}
                            <i className="mr-2"></i>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <form
                          action=""
                          onSubmit={this.handleSubmit}
                          id="training"
                        >
                          <div
                            className="form-group row"
                            style={{ marginBottom: "0.75rem" }}
                          >
                            <div className="col-lg-9">
                              {this.renderInput("title", "Training Title")}
                            </div>
                            <div className="col-lg-3 d-flex align-items-center justify-content-around">
                              <div className="form-group mt-7">
                                <label className="mb-0">Mandatory</label>

                                <div className="checkbox-list">
                                  <label className="checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="agree"
                                      value=""
                                      checked={this.state.isMandatory}
                                      onClick={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          this.setState({
                                            isMandatory: true,
                                          });
                                        } else {
                                          this.setState({
                                            isMandatory: false,
                                          });
                                        }
                                      }}
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                              <div className="form-group mt-7">
                                <label className="mb-0">Publish</label>
                                <div className="checkbox-list">
                                  <label className="checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="agree"
                                      value=""
                                      checked={this.state.isPublished}
                                      onClick={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          this.setState({
                                            isPublished: true,
                                          });
                                        } else {
                                          this.setState({
                                            isPublished: false,
                                          });
                                        }
                                      }}
                                      disabled={disabled}
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                              <div className="form-group mt-7">
                                <label className="mb-0">
                                  External Training
                                </label>

                                <div className="checkbox-list">
                                  <label className="checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="agree"
                                      value=""
                                      checked={this.state.isExternalTraining}
                                      onClick={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          this.setState({
                                            isExternalTraining: true,
                                          });
                                        } else {
                                          this.setState({
                                            isExternalTraining: false,
                                          });
                                        }
                                      }}
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="form-group row"
                            style={{ marginBottom: "0.75rem" }}
                          >
                            <div className="col-lg-12">
                              {this.renderTextarea(
                                "description",
                                "Training Description"
                              )}
                            </div>
                          </div>
                          <div
                            className="form-group row"
                            style={{ marginBottom: "0.75rem" }}
                          >
                            <div className="col-lg-3">
                              {" "}
                              <div className="form-group">
                                <label
                                  className={
                                    this.state.errors.photo ? "errorColor" : ""
                                  }
                                  htmlFor="photograph2"
                                >
                                  Upload cover photo (Image file size should not
                                  exceed 0.5 mb)
                                </label>
                                <input
                                  accept="image/*"
                                  type="file"
                                  id="photograph2"
                                  className="form-control "
                                  onChange={this.uploadFile}
                                  disabled={this.state.disable}
                                />

                                {this.state.errors.images !== "" && (
                                  <div
                                    className="mt-2"
                                    style={{
                                      color: "#f64e60",
                                      fontSize: "0.9rem",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <div>{this.state.errors.images}</div>
                                  </div>
                                )}
                              </div>
                              {!_.isEmpty(this.state.images) && (
                                <div
                                  className="mb-5"
                                  style={{ width: "50%", position: "relative" }}
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "175px",
                                      borderRadius: "5px",
                                    }}
                                    className="img-fluid"
                                    src={
                                      process.env.REACT_APP_MEDIAURL +
                                      this.state.images.original
                                    }
                                    alt=""
                                  />
                                  <a
                                    style={{
                                      position: "absolute",
                                      top: "-10px",
                                      right: "-10px",
                                    }}
                                    href="javascript:void (0)"
                                    className="btn btn-xs btn-icon btn-light btn-hover-primary"
                                    onClick={() => {
                                      this.setState({ images: {} });
                                    }}
                                  >
                                    <i className="ki ki-close icon-xs text-muted"></i>
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                          <hr />
                        </form>
                        {detail &&
                          detail.sections &&
                          detail.sections.length > 0 &&
                          detail.sections.map((e, is) => (
                            <>
                              <div class="form-group" key={is}>
                                <div className="row">
                                  <div className="col-lg-12 d-flex justify-content-between">
                                    <h4
                                      style={{
                                        color: "#001167",
                                      }}
                                    >
                                      {e.name}
                                    </h4>
                                    <div class="d-flex align-items-center">
                                      <p class="mb-0 mr-4">
                                        Display Order - {e.displayOrder}
                                      </p>
                                      <span
                                        style={{
                                          overflow: "visible",
                                          position: "relative",
                                          marginRight: "20px",
                                        }}
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                                          title="Edit details"
                                          onClick={(event) =>
                                            this.toggleEditUser(event, e)
                                          }
                                        >
                                          <span className="svg-icon svg-icon-md">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                ></rect>
                                                <path
                                                  d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                                ></path>
                                                <path
                                                  d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                  opacity="0.3"
                                                ></path>
                                              </g>
                                            </svg>
                                          </span>
                                        </a>
                                        <a
                                          href="javascript:void (0)"
                                          onClick={() => this.toggle(e._id)}
                                          className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                                          title="Delete"
                                        >
                                          <span className="svg-icon svg-icon-md">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                ></rect>
                                                <path
                                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                  fill="#000000"
                                                  fillRule="nonzero"
                                                ></path>
                                                <path
                                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                  fill="#000000"
                                                  opacity="0.3"
                                                ></path>
                                              </g>
                                            </svg>
                                          </span>
                                        </a>
                                      </span>

                                      <a
                                        href="javascript:void (0)"
                                        className="btn font-weight-bold btn-sm font-size-base ml-auto new-button"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          this.toggleAddQuestion(e._id);
                                        }}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-container="body"
                                        data-boundary="window"
                                      >
                                        Add Question
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {e.questions &&
                                e.questions.length > 0 &&
                                e.questions.map((e1, iq) => (
                                  <>
                                    <div
                                      className="col-lg-12 p-0 mb-5 d-flex justify-content-between"
                                      style={{ position: "relative" }}
                                      key={iq}
                                    >
                                      <div
                                        style={{
                                          width: "80%",
                                        }}
                                      >
                                        <span className="bullet-point">
                                          {iq + 1}.
                                        </span>
                                        <div
                                          style={{
                                            marginLeft: "20px",
                                          }}
                                        >
                                          {e1.image && (
                                            <img
                                              style={{
                                                width: "auto",
                                                height: "150px",
                                                borderRadius: "5px",
                                                marginBottom: "15px",
                                              }}
                                              src={
                                                process.env.REACT_APP_MEDIAURL +
                                                e1.image.original
                                              }
                                              alt=""
                                            />
                                          )}
                                          <div className="d-flex flex-column">
                                            <label
                                              style={{
                                                marginRight: "0",
                                              }}
                                            >
                                              {e1.title}
                                            </label>
                                            <p
                                              className="para-new-line"
                                              style={{
                                                marginRight: "0",
                                                marginBottom: "0",
                                              }}
                                            >
                                              {e1.text}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <p class="mb-0 mr-4">
                                          Display Order - {e1.displayOrder}
                                        </p>
                                        <span
                                          style={{
                                            overflow: "visible",
                                            position: "relative",
                                          }}
                                        >
                                          <a
                                            href="javascript:void(0)"
                                            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                                            title="Edit details"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              this.toggleEditQuestion(
                                                e._id,
                                                e1._id,
                                                e1
                                              );
                                            }}
                                          >
                                            <span className="svg-icon svg-icon-md">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                              >
                                                <g
                                                  stroke="none"
                                                  strokeWidth="1"
                                                  fill="none"
                                                  fillRule="evenodd"
                                                >
                                                  <rect
                                                    x="0"
                                                    y="0"
                                                    width="24"
                                                    height="24"
                                                  ></rect>
                                                  <path
                                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                    fill="#000000"
                                                    fillRule="nonzero"
                                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                                  ></path>
                                                  <path
                                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                    fill="#000000"
                                                    fillRule="nonzero"
                                                    opacity="0.3"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </span>
                                          </a>
                                          <a
                                            href="javascript:void (0)"
                                            onClick={() =>
                                              this.toggleDelQues(e._id, e1._id)
                                            }
                                            className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                                            title="Delete"
                                          >
                                            <span className="svg-icon svg-icon-md">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                              >
                                                <g
                                                  stroke="none"
                                                  strokeWidth="1"
                                                  fill="none"
                                                  fillRule="evenodd"
                                                >
                                                  <rect
                                                    x="0"
                                                    y="0"
                                                    width="24"
                                                    height="24"
                                                  ></rect>
                                                  <path
                                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                    fill="#000000"
                                                    fillRule="nonzero"
                                                  ></path>
                                                  <path
                                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                    fill="#000000"
                                                    opacity="0.3"
                                                  ></path>
                                                </g>
                                              </svg>
                                            </span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                    {e1.questionType === "3" &&
                                      e1.readOnly == false && (
                                        <div style={{ width: "30%" }}>
                                          {this.renderTextarea(
                                            "answerType",
                                            ""
                                          )}
                                        </div>
                                      )}
                                    {(e1.questionType === "1" ||
                                      e1.questionType === "4") &&
                                      e1.options.map((e2, i2) => (
                                        <div style={{ marginLeft: "20px" }}>
                                          <div class="form-group" key={i2}>
                                            <div class="radio-list">
                                              <label
                                                class="radio"
                                                htmlFor={e2 + e1._id}
                                              >
                                                <input
                                                  id={e2 + e1._id}
                                                  type="radio"
                                                  name={e2 + e1._id}
                                                  checked={
                                                    e2 === e1.answers[0]
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <span></span>
                                                {e1.questionType === "4"
                                                  ? _.capitalize(e2)
                                                  : e2}
                                                <div>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    {e1.questionType === "2" &&
                                      e1.options.map((e2, i2) => (
                                        <div
                                          style={{ marginLeft: "20px" }}
                                          key={i2}
                                        >
                                          <div class="form-group">
                                            <div class="checkbox-list">
                                              <label
                                                class="checkbox"
                                                htmlFor={e1._id + e2}
                                              >
                                                <input
                                                  type="checkbox"
                                                  id={e1._id + e2}
                                                  name={e1._id + e2}
                                                  checked={
                                                    e1.answers.find(
                                                      (ans) => ans === e2
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <span></span>
                                                {e2}{" "}
                                                <div>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                ))}
                              {is < detail.sections.length - 1 && <hr />}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddSection
            isShow={this.state.isShow}
            toggleAddUser={this.toggleAddUser}
            id={detail._id}
            editing={this.state.editing}
            editData={this.state.editData}
            setEditing={(editing) => this.setState({ editing })}
            setEditData={(editData) => this.setState({ editData })}
          />
          <AddQuestion
            isShow={this.state.questionModal}
            toggleAddQuestion={this.toggleAddQuestion}
            sectionId={this.state.sectionId}
            questionId={this.state.questionId}
            id={detail._id}
            editingQuestion={this.state.editingQuestion}
            editDataQuestion={this.state.editDataQuestion}
            setEditingQuestion={(editingQuestion) =>
              this.setState({ editingQuestion })
            }
            setEditingData={(editDataQuestion) =>
              this.setState({ editDataQuestion })
            }
          />
          <ModalPopup
            show={this.state.modal}
            onHide={this.toggle}
            delete_id={this.state.delete_id}
            deleted={this.handleDeleteSection}
          />
          <ModalPopup
            show={this.state.modalQues}
            onHide={this.toggleDelQues}
            delete_id={this.state.delete_id}
            deleted={this.handleDeleteQuestion}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  detail: getAssessment(state).detail,
});
const mapDispatchToProps = (dispatch) => ({
  loadDetailAssessment: (id) => dispatch(loadDetailAssessment(id)),
  updateTraining: (id, data, callback) =>
    dispatch(updateTraining(id, data, callback)),
  deleteSection: (id, data, callback) =>
    dispatch(deleteSection(id, data, callback)),
  deleteQuestion: (id, data, callback) =>
    dispatch(deleteQuestion(id, data, callback)),
  uploadTrainingImage: (data, callback) =>
    dispatch(uploadTrainingImage(data, callback)),
  updateQuestion: (id, data, callback) =>
    dispatch(updateQuestion(id, data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentForm);
