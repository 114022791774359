import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import {
  emailRequired,
  lastNameRequired,
  firstNameRequired,
  informationSaved,
  failed,
} from "../common/misc";
import {
  loadAllAdministrator,
  updateAdministrator,
  getAdministrator,
} from "../../store/administrator";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";

const initialState = {
  data: {
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    mobile: "",
  },
  errors: {
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    mobile: "",
  },
  userType: "admin",
};

class EditAdministrator extends Form {
  state = initialState;
  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),
    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
    userName: Joi.string()
      .required()
      .label("User Name")
      .error(() => {
        return { message: "User Name field is required." };
      }),
    // mobile: Joi.number()
    //   .required()
    //   .label("Mobile")
    //   .error(() => {
    //     return { message: "Mobile field is required." };
    //   }),
    mobile: Joi.number().allow(""),
  };
  doSubmit = () => {
    const { firstName, lastName, email, userName, mobile } = this.state.data;
    const id = this.props.id;
    var formData = {
      firstName,
      lastName,
      // email,
      userName,
      mobile,
      userType: this.state.userType,
    };
    this.props.updateAdministrator(id, formData, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({
        page: this.props.initialPage,
        sort: this.props.sort,
        order: this.props.order,
        userType: this.props.userType,
      });
      this.props.toggleEditUser();
      //toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  componentDidMount = () => {
    this.setState({
      data: {
        firstName: this.props.data.firstName,
        lastName: this.props.data.lastName,
        email: this.props.data.email,
        userName: this.props.data.userName,
        mobile: this.props.data.mobile,
      },
      userType: this.props.data.userType,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        data: {
          firstName: nextProps.data.firstName,
          lastName: nextProps.data.lastName,
          email: nextProps.data.email,
          userName: nextProps.data.userName,
          mobile: nextProps.data.mobile,
        },
        userType: nextProps.data.userType,
      });
    }
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.editShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Edit Administrator</h3>
            <Link
              to={"#"}
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleEditUser}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </Link>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("firstName", "First Name")}
            {this.renderInput("lastName", "Last Name")}
            {this.renderInput("userName", "User Name")}
            {this.renderInput("email", "Email", "email", true)}
            {this.renderInput("mobile", "Mobile")}
            <div className="form-group">
              <label>Master Admin</label>
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree"
                    value=""
                    checked={this.state.userType === "superadmin"}
                    onChange={(e) => {
                      const { value, checked } = e.target;
                      if (checked) {
                        this.setState({ userType: "superadmin" });
                      } else {
                        this.setState({ userType: "admin" });
                      }
                    }}
                  />
                  <span></span>
                </label>
              </div>
            </div>
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.editShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  administrator: getAdministrator(state).administrator,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAdministrator: (params) => dispatch(loadAllAdministrator(params)),
  updateAdministrator: (id, data, callback) =>
    dispatch(updateAdministrator(id, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdministrator);
