import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deAssign, loadAllAssessment } from "../../store/assessment";
import AlertError from "../common/alertError";

class AssignedUser extends Component {
  state = {
    users: [],
  };
  toggleClose = () => {
    this.setState({ users: [] });
    this.props.toggleAssignedFlyer();
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    var formData = {
      trainings: [this.props.trainingId],
      users: this.state.users,
    };
    this.props.deAssign(formData, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAssessment({
        sort: "createdAt",
        order: "desc",
        page: this.props.initialPage,
      });
      this.toggleClose();
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    return (
      <>
        <div
          style={{ width: "700px", overflowY: "scroll" }}
          className={
            this.props.assignedModal === true
              ? "offcanvas offcanvas-right p-10 offcanvas-on"
              : "offcanvas offcanvas-right p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Assigned Users</h3>
            <div>
              <button
                type="submit"
                className="btn btn-sm font-weight-bolder new-button"
                style={{ padding: "0.6rem 2.5rem" }}
                disabled={this.state.users.length === 0 ? true : false}
                form="deassign"
              >
                Remove
              </button>
              <a
                href="javascript:void (0)"
                className="btn btn-xs btn-icon btn-light btn-hover-primary ml-4"
                onClick={this.toggleClose}
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            </div>
          </div>
          <hr />
          <form action="" id="deassign" onSubmit={this.handleSubmit}>
            {this.props.assignedData &&
              this.props.assignedData.length > 0 &&
              this.props.assignedData.map((user) => (
                <div
                  className="d-flex align-items-center"
                  style={{ padding: "10px 0" }}
                >
                  <div className="font-weight-bolder font-size-lg mb-0">
                    <div>
                      <div class="checkbox-list">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            value={user._id}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              let users = [...this.state.users];
                              if (checked) {
                                users.push(value);
                              } else {
                                users = users.filter((u) => u !== value);
                              }
                              this.setState({ users });
                            }}
                            checked={
                              this.state.users.find((u) => u === user._id)
                                ? true
                                : false
                            }
                          />
                          <span></span>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6 className="m-0" style={{ fontSize: "1rem" }}>
                      {user.fullName ? user.fullName : ""}
                    </h6>
                    <p className="mb-0">{user.email ? user.email : ""}</p>
                    <p className="mb-0">{user.mobile ? user.mobile : ""}</p>
                  </div>
                </div>
              ))}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                form="deassign"
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
              >
                Remove
              </button>
            </div>
          </form>
        </div>
        {this.props.assignedModal === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadAllAssessment: (param) => dispatch(loadAllAssessment(param)),
  deAssign: (data, callback) => dispatch(deAssign(data, callback)),
});

export default connect(null, mapDispatchToProps)(AssignedUser);
