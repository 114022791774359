import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import AddAdministrator from "../administrator/addAdministrator";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import AdministratorGrid from "./administratorGrid";
import AdministratorEmpty from "./adminEmpty";
import SortingIcon from "../common/sortingIcon";
import HelmetComponent from "../common/helmetComponent";
import {
  getAdministrator,
  loadAllAdministrator,
  deletedAdministrator,
  updateAdministrator,
} from "../../store/administrator";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { deleted, activated, deactivated, failed } from "../common/misc";
import SortingIconUp from "../common/sortingIconUp";
import Search from "./search";
import { debounce } from "lodash";

class AdminTable extends Component {
  state = {
    userShow: false,
    isShow: false,
    modal: false,
    active: false,
    id: "",
    admin_id: "",
    status: "",
    msg: "",
    data: "",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    editShow: false,
    delete_id: "",
    changePass: "false",
    resPass: "false",
    change_id: "",
    asside: false,
    asside2: false,
    asside3: false,
    sort: "createdAt",
    order: "desc",
    userType: "admin",
    keyword: "",
  };

  handleSearch = debounce(() => {
    this.props.loadAllAdministrator({
      page: "1",
      sort: this.state.sort,
      order: this.state.order,
      userType: this.state.userType,
      keyword: this.state.keyword,
    });
  }, 300);
  onSearch = (value) => {
    this.setState(
      {
        keyword: value,
      },
      () => this.handleSearch()
    );
  };

  reset = () => {
    this.setState(
      {
        keyword: "",
      },
      () => this.handleSearch()
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort !== prevState.sort ||
      this.state.order !== prevState.order
    ) {
      this.props.loadAllAdministrator({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
        userType: this.state.userType,
      });
    }
  };

  sortByFirstName = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "firstName",
        order: "asc",
      });
    else {
      this.setState({
        sort: "firstName",
        order: "desc",
      });
    }
  };
  sortByLastName = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "lastName",
        order: "asc",
      });
    else {
      this.setState({
        sort: "lastName",
        order: "desc",
      });
    }
  };
  sortByEmail = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "email",
        order: "asc",
      });
    else {
      this.setState({
        sort: "email",
        order: "desc",
      });
    }
  };
  sortByCreatedAt = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "createdAt",
        order: "asc",
      });
    else {
      this.setState({
        sort: "createdAt",
        order: "desc",
      });
    }
  };
  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      admin_id: id,
      status: status,
      msg: msg,
    });
  };
  toggleEditUser = (data, id) => {
    this.setState({
      editShow: !this.state.editShow,
      data: data,
      id: id,
    });
  };
  toggleChangePassword = (id) => {
    this.setState({
      changePass: !this.state.changePass,
      change_id: id,
    });
  };
  toggleResPassword = (id) => {
    this.setState({
      resPass: !this.state.resPass,
      change_id: id,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };
  UpdateAdmin = (id) => {
    const formData = {
      is_active: this.state.status,
    };
    this.props.updateAdministrator(id, formData, this.callbackActive);
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
        userType: this.state.userType,
      });
      this.setState({
        active: !this.state.active,
      });
      if (this.state.status === true) {
        //toast(<AlertSuccess message={activated} />);
      } else {
        //toast(<AlertSuccess message={deactivated} />);
      }
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  deletedAdministrator = (id) => {
    this.props.deletedAdministrator(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
        userType: this.state.userType,
      });
      this.setState({
        modal: !this.state.modal,
      });
      //toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadAllAdministrator({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
        userType: this.state.userType,
      });
    }
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page == 0 ? 1 : data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadAllAdministrator({
        page: data.page,
        sort: this.state.sort,
        order: this.state.order,
        userType: this.state.userType,
      });
    }
  };
  render() {
    const { administrator, loading } = this.props.administrator;
    const { userType } = JSON.parse(localStorage.getItem("admin_details"));
    const { total_record, current_page, record_per_page, total_page } =
      this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Administrators Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Administrators
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          {total_record > 0
                            ? "Total :" + " " + total_record + " " + "records"
                            : "Total : 0 record"}
                        </span>
                        <Search
                          keyword={this.state.keyword}
                          onSearch={this.onSearch}
                          reset={this.reset}
                        />
                      </div>
                    </div>
                    {userType === "superadmin" && (
                      <div className="d-flex align-items-center">
                        <a
                          href="#/"
                          className="btn btn-primary font-weight-bold btn-sm font-size-base ml-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleAddUser();
                          }}
                          data-toggle="tooltip"
                          data-placement="right"
                          data-container="body"
                          data-boundary="window"
                        >
                          Add Administrator
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      {/* <div className="card-header flex-wrap border-0 pt-6 pb-0">
                        <div className="card-title">
                          <h3 className="card-label">
                            Administrators Management
                            <span className="d-block text-333 pt-2 font-size-sm">
                              You can view/add/edit/delete/activate/deactivate
                              administrators.
                            </span>
                          </h3>
                        </div>
                      </div> */}
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>
                                    First Name
                                    {this.state.sort === "firstName" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByFirstName}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByFirstName}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>
                                    Last Name
                                    {this.state.sort === "lastName" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByLastName}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByLastName}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Email
                                    {this.state.sort === "email" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={this.sortByEmail} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByEmail}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "130px" }}>
                                    Master Admin
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "120px" }}>
                                    Created At
                                    {this.state.sort === "createdAt" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreatedAt}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreatedAt}
                                      />
                                    )}
                                  </span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "145px" }}>Action</span>
                                </th>
                              </tr>
                            </thead>
                            {loading === false && administrator.length === 0 ? (
                              <AdministratorEmpty />
                            ) : (
                              <AdministratorGrid
                                changePass={this.state.changePass}
                                toggleChangePassword={this.toggleChangePassword}
                                change_id={this.state.change_id}
                                delete_id={this.state.delete_id}
                                initialPage={this.state.currentPage}
                                editShow={this.state.editShow}
                                toggleEditUser={this.toggleEditUser}
                                data={this.state.data}
                                msg={this.state.msg}
                                status_id={this.state.admin_id}
                                Update={this.UpdateAdmin}
                                modalActive={this.state.active}
                                toggleAcitve={this.toggleAcitve}
                                administrator={administrator}
                                modal={this.state.modal}
                                toggle={this.toggle}
                                id={this.state.id}
                                deleted={this.deletedAdministrator}
                                resPass={this.state.resPass}
                                toggleResPassword={this.toggleResPassword}
                                sort={this.state.sort}
                                order={this.state.order}
                                userType={this.state.userType}
                              />
                            )}
                          </table>
                          <Pagination
                            totalRecords={total_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddAdministrator
            initialPage={this.state.currentPage}
            isShow={this.state.isShow}
            toggleAddUser={this.toggleAddUser}
            sort={this.state.sort}
            order={this.state.order}
            userType={this.state.userType}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  administrator: getAdministrator(state),
  pagination: getAdministrator(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAdministrator: (params) => dispatch(loadAllAdministrator(params)),
  deletedAdministrator: (id, callback) =>
    dispatch(deletedAdministrator(id, callback)),
  updateAdministrator: (id, data, callbackActive) =>
    dispatch(updateAdministrator(id, data, callbackActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
