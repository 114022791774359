import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "shops",
  initialState: {
    products: [],
    pagination: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    productRequested: (shop, action) => {
      shop.loading = true;
    },

    productReceived: (shop, action) => {
      shop.products = action.payload.data;
      shop.pagination = action.payload.pagination;
      shop.loading = false;
      shop.lastFetch = Date.now();
    },

    productRequestFailed: (shop, action) => {
      shop.loading = false;
    },
  },
});

export const { productRequested, productReceived, productRequestFailed } =
  slice.actions;
export default slice.reducer;

// Action Creators
const url = "shops/products/";
const urlSection = "trainings/sections/";
const urlQuestion = "trainings/sections/questions/";
const url1 = "assesment/question/";

export const loadAllProduct = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: productRequested.type,
      onSuccess: productReceived.type,
      onError: productRequestFailed.type,
    })
  );
};

export const updateProduct = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
    })
  );
};
export const addProduct = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data,
    })
  );
};

export const deleteProduct = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};

export const uploadProductImage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "shops/products/displayPicture",
      method: "post",
      data,
    })
  );
};

export const getShop = createSelector(
  (state) => state.entities.shops,
  (shops) => shops
);
