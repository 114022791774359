import { combineReducers } from "redux";
import adminReducer from "./admin";
import administratorReducer from "./administrator";
import assessmentReducer from "./assessment";
import usersReducer from "./users";
import miscReducer from "./misc";
import shopsReducer from "./shop";
import requestsReducer from "./request";

export default combineReducers({
  admin: adminReducer,
  administrator: administratorReducer,
  assessment: assessmentReducer,
  users: usersReducer,
  misc: miscReducer,
  shops: shopsReducer,
  request: requestsReducer,
});
