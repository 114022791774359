import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Country } from "../utils/countryCode";

import { emailRequired, informationSaved } from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";
import {
  addUser,
  getUsers,
  loadAllUsers,
  addExternalUser,
} from "../../store/users";

const initialState = {
  code: "",
  data: {
    fullName: "",
    countryCode: "",
    mobile: "",
  },
  errors: {
    mobile: "",
    fullName: "",
    countryCode: "",
  },
};
class AddUser extends Form {
  state = initialState;
  schema = {
    fullName: Joi.string()
      .required()
      .label("fullName")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Name is required.";
              break;
          }
        });
        return errors;
      }),
    countryCode: Joi.string()
      .required()
      .label("Country Code")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Country Code is required.";
              break;
          }
        });
        return errors;
      }),
    mobile: Joi.string()
      .required()
      .label("Mobile")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Mobile is required.";
              break;
          }
        });
        return errors;
      }),
  };

  randomStr(len, arr) {
    var ans = "";
    for (var i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }

  doSubmit = () => {
    const { fullName, mobile } = this.state.data;
    var formdata = {
      fullName,
      mobile,
    };
    formdata.countryCode = this.state.code;

    const uniqueId = this.randomStr(15, "abcdefghijklmnop1234567890");
    formdata.externalUserId = uniqueId;

    this.props.addExternalUser(formdata, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllUsers({
        page: this.props.initialPage,
        sort: "createdAt",
        order: "desc",
      });
      this.props.toggleAddUser();
      toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  toggleAddClose = () => {
    this.setState(initialState);
    this.props.toggleAddUser();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.data.countryCode !== this.state.data.countryCode &&
      this.state.data.countryCode !== ""
    ) {
      const a = Country.filter(
        (item) => item.name === this.state.data.countryCode
      );
      const code = a[0].dial_code;
      this.setState({ code });
    }
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add External User</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleAddClose}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("fullName", "Full Name")}
            {this.renderSelect(
              "countryCode",
              "Country Code",
              Country,
              false,
              "Uganda"
            )}
            {this.renderInput("mobile", "Mobile")}

            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-5">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: getUsers(state),
});
const mapDispatchToProps = (dispatch) => ({
  loadAllUsers: (params) => dispatch(loadAllUsers(params)),
  addExternalUser: (params, callback) =>
    dispatch(addExternalUser(params, callback)),
  addUser: (data, callback) => dispatch(addUser(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
