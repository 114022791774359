import axios from "axios";
import * as actions from "../api";
const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { status } = error.response;
    console.log(status);
    if (status === UNAUTHORIZED) {
      const apiUrl = error.request.responseURL.split("/");
      if (
        apiUrl[apiUrl.length - 1] !== "refreshToken" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const headers = {
          Authorization: process.env.REACT_APP_TOKEN,
        };
        headers["refreshToken"] = localStorage.getItem("x-refresh-token");
        try {
          const response = await axios.request({
            baseURL: process.env.REACT_APP_APIBASE,
            headers,
            url: "auth/refreshToken",
            method: "POST",
          });
          localStorage.setItem("jwt", response.data.data.jwt)
          //setJwt(response.data.data.jwt, false);
          axios.defaults.headers.common["jwt"] = response.data.data.jwt;
          originalRequest.headers["jwt"] = response.data.data.jwt;
          return axios(originalRequest);
        } catch (error) {
          localStorage.clear()
          window.location.href = "/";
        }
      }
    }
    return Promise.reject(error);
  }
);

const api = ({ dispatch }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const {
    url,
    method,
    data,
    params,
    onStart,
    onSuccess,
    onError,
    callback = false,
  } = action.payload;
  if (onStart) dispatch({ type: onStart });

  next(action);

  const headers = {
    "Authorization": process.env.REACT_APP_TOKEN,
  };

  if (localStorage.getItem("x-auth-token"))
    headers["jwt"] = localStorage.getItem("x-auth-token");

  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_APIBASE,
      headers,
      url,
      method,
      params,
      data,
    });
    // General
    dispatch(actions.apiCallSuccess(response.data));
    // Specific
    if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    if (callback) callback(response);
  } catch (error) {
    // General
    dispatch(actions.apiCallFailed(error.message));
    // Specific
    if (onError) dispatch({ type: onError, payload: error.message });
    if (callback) callback(error.response);
  }
};

export default api;
