import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "assessment",
  initialState: {
    assessment: [],
    trainingAnalytics: [],
    pagination: {},
    update: {},
    question: {},
    add: {},
    addqestion: {},
    detail: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    assessmentRequested: (assessment, action) => {
      assessment.loading = true;
    },

    assessmentReceived: (assessment, action) => {
      assessment.assessment = action.payload.data;
      assessment.pagination = action.payload.pagination;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    assessmentAnalyticsRequested: (assessment, action) => {
      assessment.loading = true;
    },

    assessmentAnalyticsReceived: (assessment, action) => {
      assessment.trainingAnalytics = action.payload.data;
    },

    assessmentAnalyticsFailed: (assessment, action) => {
      assessment.loading = false;
    },
    assessmentDetailRequested: (assessment, action) => {
      assessment.loading = true;
    },

    assessmentDetailReceived: (assessment, action) => {
      assessment.detail = action.payload.data;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentDetailRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    updatedAssessment: (assessment, action) => {
      assessment.update = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    updatedAssessmentRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    updatedQuestionsAssessment: (assessment, action) => {
      assessment.question = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    updatedQuestionAssessmentRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
    assessmentAdded: (assessment, action) => {
      assessment.add = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentAddRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },

    assessmentQuestionAdded: (assessment, action) => {
      assessment.addqestion = action.payload;
      assessment.loading = false;
      assessment.lastFetch = Date.now();
    },

    assessmentAddQuestionRequestFailed: (assessment, action) => {
      assessment.loading = false;
    },
  },
});

export const {
  assessmentQuestionAdded,
  assessmentAddQuestionRequestFailed,
  assessmentAnalyticsRequested,
  assessmentAnalyticsReceived,
  assessmentAnalyticsFailed,
  assessmentRequested,
  assessmentReceived,
  assessmentRequestFailed,
  assessmentDetailRequested,
  assessmentDetailReceived,
  assessmentDetailRequestFailed,
  updatedAssessment,
  updatedAssessmentRequestFailed,
  updatedQuestionsAssessment,
  updatedQuestionAssessmentRequestFailed,
  assessmentAdded,
  assessmentAddRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "trainings/";
const urlSection = "trainings/sections/";
const urlQuestion = "trainings/sections/questions/";
const url1 = "assesment/question/";
const urlAnalytics = "organizations/trainingAnalytics";

export const loadAllAssessment = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: assessmentRequested.type,
      onSuccess: assessmentReceived.type,
      onError: assessmentRequestFailed.type,
    })
  );
};

export const loadTrainingAnalytics =
  (params, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: urlAnalytics,
        params,
        callback,
        onStart: assessmentAnalyticsRequested.type,
        onSuccess: assessmentAnalyticsReceived.type,
        onError: assessmentAnalyticsFailed.type,
      })
    );
  };

export const loadDetailAssessment = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      onStart: assessmentDetailRequested.type,
      onSuccess: assessmentDetailReceived.type,
      onError: assessmentDetailRequestFailed.type,
    })
  );
};
export const updateTraining = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedAssessment.type,
      onError: updatedAssessmentRequestFailed.type,
    })
  );
};
export const addTrainings = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url,
      method: "post",
      data,
      onSuccess: assessmentAdded.type,
      onError: assessmentAddRequestFailed.type,
    })
  );
};

export const addSection = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: urlSection + id,
      method: "post",
      data,
    })
  );
};

export const updateSection = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: urlSection + id,
      method: "PUT",
      data,
      callback,
    })
  );
};

export const addQuestion = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: urlQuestion + id,
      method: "post",
      data,
      callback,
      onSuccess: assessmentQuestionAdded.type,
      onError: assessmentAddQuestionRequestFailed.type,
    })
  );
};

export const updateQuestion = (id, data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlQuestion + id,
      method: "PUT",
      data,
      callback,
      onSuccess: updatedQuestionsAssessment.type,
      onError: updatedQuestionAssessmentRequestFailed.type,
    })
  );
};
export const deletedTraining = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};

export const deleteSection = (id, data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlSection + id,
      method: "delete",
      data,
      callback,
    })
  );
};

export const deleteQuestion = (id, data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlQuestion + id,
      method: "delete",
      data,
      callback,
    })
  );
};

export const uploadTrainingImage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "trainings/displayPicture",
      method: "post",
      data,
    })
  );
};
export const uploadQuestionImage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "trainings/sections/questions/questionImage/uploadImage",
      method: "post",
      data,
    })
  );
};

export const trainingAssign = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url + "assign",
      method: "post",
      data,
    })
  );
};

export const deAssign = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url + "assign/deallocate",
      method: "put",
      data,
    })
  );
};
export const getAssessment = createSelector(
  (state) => state.entities.assessment,
  (assessment) => assessment
);
