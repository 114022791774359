import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

import _, { debounce } from "lodash";
import users, {
  getUsers,
  loadAllUsers,
  loadAllUsersAssign,
} from "../../store/users";
import { loadAllAssessment, trainingAssign } from "../../store/assessment";
import AssignedUser from "./assignedUser";

const initialState = {
  users: [],
  loading: false,
  disable: false,
  keyword: "",
};
class AssignUser extends Component {
  state = initialState;

  toggleClose = () => {
    this.setState(initialState);
    this.props.setTrainings("");
    this.props.setTrainingsName("");
    this.props.setAssignedUser([]);
    this.props.toggleAssignFlyer();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.assignModal &&
      this.props.assignModal !== prevProps.assignModal
    ) {
      this.props.loadAllUsersAssign();
      let users = [];
      this.props.assignedUser.map((user) => {
        users.push(user._id);
      });
      this.setState({ users });
    }
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    var formData = {
      trainings: [this.props.trainings],
      users: this.state.users,
    };
    this.props.trainingAssign(formData, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAssessment({
        page: this.props.intialPage,
        sort: "createdAt",
        order: "desc",
      });
      this.toggleClose();
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  onSearchName = debounce(() => {
    this.props.loadAllUsersAssign({
      keyword: this.state.keyword,
    });
  }, 300);

  handleCheck = (id) => {
    const filtered = this.props.assignedUser.filter((user) => user._id === id);
    return filtered.length > 0 ? true : false;
  };

  render() {
    return (
      <>
        <div
          style={{ width: "700px", overflowY: "scroll" }}
          className={
            this.props.assignModal === true
              ? "offcanvas offcanvas-right p-10 offcanvas-on"
              : "offcanvas offcanvas-right p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Assign Users</h3>
            <div>
              <button
                type="submit"
                className="btn btn-sm font-weight-bolder new-button"
                style={{ padding: "0.6rem 2.5rem" }}
                disabled={this.state.users.length === 0 ? true : false}
                form="assign"
              >
                Assign
              </button>
              <a
                href="javascript:void (0)"
                className="btn btn-xs btn-icon btn-light btn-hover-primary ml-4"
                onClick={this.toggleClose}
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            </div>
          </div>
          <hr />
          <div className="offcanvas-headerpb-5">
            <p
              className="mb-5"
              style={{
                fontWeight: "600",
                fontSize: "1.3rem",
              }}
            >
              Selected Training
            </p>
            <p>{this.props.trainingsName}</p>
          </div>
          <hr />
          <div className="search-block mb-5">
            <div className="d-flex col-lg-12 p-0">
              {" "}
              <div
                className="input-group input-group-sm input-group-solid"
                style={{ maxWidth: "100%" }}
              >
                <input
                  name="fullName"
                  id="fullName"
                  className="form-control"
                  placeholder="Search By Name, Email or Mobile"
                  autoComplete="off"
                  value={this.state.keyword}
                  onChange={(e) => {
                    this.setState({ keyword: e.target.value }, () =>
                      this.onSearchName()
                    );
                  }}
                />
                <div className="input-group-append">
                  <a href="javascript:void (0)" type="submit">
                    <span className="input-group-text">
                      <span className="svg-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                          </g>
                        </svg>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <form action="" id="assign" onSubmit={this.handleSubmit}>
            {this.props.usersAssign &&
              this.props.usersAssign.length > 0 &&
              this.props.usersAssign.map((user, i) => (
                <div
                  className="d-flex align-items-center"
                  style={{ padding: "10px 0" }}
                  key={user._id}
                >
                  <div className="font-weight-bolder font-size-lg mb-0">
                    <div>
                      <div class="checkbox-list">
                        <label class="checkbox" htmlFor={user._id}>
                          <input
                            type="checkbox"
                            id={user._id}
                            name={user._id}
                            value={user._id}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              let users = [...this.state.users];
                              if (checked) {
                                users.push(value);
                              } else {
                                users = users.filter((u) => u !== value);
                              }
                              this.setState({ users });
                            }}
                            checked={this.state.users.find(
                              (u) => u === user._id
                            )}
                          />
                          <span></span>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6 className="m-0" style={{ fontSize: "1rem" }}>
                      {user.fullName ? user.fullName : ""}
                    </h6>
                    <p className="mb-0">{user.email ? user.email : ""}</p>
                    <p className="mb-0">{user.mobile ? user.mobile : ""}</p>
                  </div>
                </div>
              ))}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                form="assign"
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
                disabled={this.state.users.length === 0 ? true : false}
              >
                Assign
              </button>
            </div>
          </form>
        </div>
        {this.props.assignModal === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersAssign: getUsers(state).usersAssign,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllAssessment: (param) => dispatch(loadAllAssessment(param)),
  loadAllUsersAssign: (param) => dispatch(loadAllUsersAssign(param)),
  trainingAssign: (data, callback) => dispatch(trainingAssign(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignUser);
