import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import {
  emailRequired,
  lastNameRequired,
  firstNameRequired,
  informationSaved,
} from "../common/misc";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";
import { loadAllUsers, updateUser } from "../../store/users";
import { getMisc, loadCountry } from "../../store/misc";
import { Country } from "../utils/countryCode";

const initialState = {
  code: "",
  data: {
    fullName: "",
    mobile: "",
    countryCode: "",
    load: false,
  },
  errors: {
    fullName: "",
    mobile: "",
    countryCode: "",
  },
};

class EditOrg extends Form {
  state = initialState;
  schema = {
    fullName: Joi.string()
      .required()
      .label("fullName")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Name is required.";
              break;
          }
        });
        return errors;
      }),
    countryCode: Joi.string()
      .required()
      .label("Country Code")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Country Code is required.";
              break;
          }
        });
        return errors;
      }),
    mobile: Joi.string()
      .required()
      .label("Mobile")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Mobile is required.";
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    const code = this.state.code;
    const { fullName, mobile } = this.state.data;
    const countryCode = code;
    const id = this.props.id;

    var formData = {
      fullName,
      countryCode,
      mobile,
    };
    this.props.updateUser(id, formData, this.callback);
  };

  callback = (res) => {
    if (res && res.status && res.status === 200) {
      this.props.loadAllUsers({
        sort: "createdAt",
        order: "desc",
        page: this.props.initialPage,
      });
      this.props.toggleEditUser();
      //toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.editShow && prevProps.editShow !== this.props.editShow) {
      const data = {
        fullName: this.props.data.fullName && this.props.data.fullName,
        mobile: this.props.data.mobile,
      };
      const countryCode = Country.filter(
        (item) => item.dial_code === this.props.data.countryCode
      );

      const code = this.props.data.countryCode;
      data.countryCode = countryCode.length > 0 ? countryCode[0].name : "";

      this.setState({ data, code, load: true });
    }
    if (
      prevState.data.countryCode &&
      prevState.data.countryCode !== this.state.data.countryCode
    ) {
      const data = { ...this.state.data };
      const codeList = Country.filter(
        (item) => item.name === this.state.data.countryCode
      );
      if (codeList.length > 0) {
        const code = codeList && codeList[0].dial_code;
        this.setState({ data, code });
      }
    }
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.editShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Edit User</h3>
            <Link
              to={"#"}
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.props.toggleEditUser}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </Link>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("fullName", "Name")}
            {this.renderSelect(
              "countryCode",
              "Country Code",
              Country,
              false,
              this.state.data.countryCode
            )}
            {this.renderInput("mobile", "Mobile")}

            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.editShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  country: getMisc(state).country,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllUsers: (params) => dispatch(loadAllUsers(params)),
  loadCountry: (params) => dispatch(loadCountry(params)),
  updateUser: (id, data, callback) => dispatch(updateUser(id, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrg);
