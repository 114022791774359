import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";

import { informationSaved, failed } from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import {
  addSection,
  getAssessment,
  loadDetailAssessment,
  updateSection,
} from "../../store/assessment";

const initialState = {
  data: {
    name: "",
    displayOrder: "",
  },
  errors: {
    name: "",
    displayOrder: "",
  },
};
class AddSection extends Form {
  state = initialState;
  schema = {
    name: Joi.string()
      .required()
      .label("Name")
      .error(() => {
        return { message: "Name is required" };
      }),
    displayOrder: Joi.number()
      .required()
      .label("Name")
      .error(() => {
        return { message: "Display order is required" };
      }),
  };

  doSubmit = () => {
    const { name, displayOrder } = this.state.data;
    var formdata;
    formdata = {
      name,
      displayOrder,
    };
    if (this.props.editing) formdata.section = this.props.editData._id;
    if (this.props.editing) {
      this.props.updateSection(this.props.id, formdata, this.callback);
    } else {
      this.props.addSection(this.props.id, formdata, this.callback);
    }
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(this.props.id);
      this.toggleClose();
      // toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  toggleClose = (e) => {
    if (e) e.preventDefault();
    this.setState(
      {
        data: {
          name: "",
          displayOrder: "",
        },
        errors: {
          name: "",
          displayOrder: "",
        },
      },
      () => {
        this.props.setEditing(false);
        this.props.setEditData(null);
        this.props.toggleAddUser();
      }
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.editing &&
      this.props.editData !== null &&
      this.props.editData !== prevProps.editData
    ) {
      // console.log("fsdv");
      const { data } = this.state;
      data.name = this.props.editData.name;
      data.displayOrder = this.props.editData.displayOrder;

      this.setState({ data });
    }
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">
              {this.props.editing ? "Edit Section" : "Add Section"}
            </h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={(e) => {
                this.toggleClose();
              }}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("name", "Name")}
            {this.renderInput("displayOrder", "Display order", "number")}
            {/* {this.renderTextarea("description", "Description")} */}

            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  detail: getAssessment(state).detail,
});
const mapDispatchToProps = (dispatch) => ({
  loadDetailAssessment: (id) => dispatch(loadDetailAssessment(id)),
  addSection: (id, data, callback) => dispatch(addSection(id, data, callback)),
  updateSection: (id, data, callback) =>
    dispatch(updateSection(id, data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddSection);
