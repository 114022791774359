import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import {
  loadAllAdministrator,
  addAdministrator,
  getAdministrator,
} from "../../store/administrator";

import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  emailRequired,
  firstNameRequired,
  lastNameRequired,
  informationSaved,
  failed,
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";

const initialState = {
  data: {
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    userName: "",
    mobile: "",
  },
  errors: {
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    userName: "",
    mobile: "",
  },
  master: false,
  userType: "admin",
};
class AddAdministrator extends Form {
  state = initialState;
  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),
    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),
    password: Joi.string()
      .min(8)
      .max(16)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_.+-])[A-Za-z\d@#$!%*?&_.+-]{6,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          if (err.type === "string.regex.name") {
            if (err.context.value === "") {
              err.message = "Password is required.";
            } else {
              err.message =
                "Password should be between 8 - 16 characters long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
            }
          }
          switch (err.type) {
            case "any.required":
              err.message = "Password required";
              break;

            case "any.empty":
              err.message = "Password required";
              break;

            case "string.min":
              err.message =
                "Password should be between 8 - 16 characters long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
              break;

            case "string.max":
              err.message =
                "Password should be between 8 - 16 characters long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
              break;
            default:
          }
        });
        return errors;
      }),
    email: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: emailRequired };
      }),
    userName: Joi.string()
      .required()
      .label("User Name")
      .error(() => {
        return { message: "User Name is required" };
      }),
    mobile: Joi.number().allow(""),
  };

  doSubmit = () => {
    const { firstName, lastName, password, email, mobile, userName } =
      this.state.data;
    var formdata = {
      firstName,
      lastName,
      email,
      password,
      userType: this.state.userType,
      userName,
    };
    if (mobile) {
      formdata.mobile = mobile;
    }
    this.props.addAdministrator(formdata, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({
        page: this.props.initialPage,
        sort: this.props.sort,
        order: this.props.order,
        userType: this.props.userType,
      });
      this.props.toggleAddUser();
      //toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  toggleAddClose = () => {
    this.setState(initialState);
    this.props.toggleAddUser();
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add Administrator</h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleAddClose}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                {this.renderInput("firstName", "First Name")}
              </div>
              <div className="col-lg-6">
                {this.renderInput("lastName", "Last Name")}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                {this.renderInput("userName", "Username")}
              </div>
              <div className="col-lg-6">
                {this.renderInput("mobile", "Mobile")}
              </div>
            </div>
            {this.renderInput("email", "Email", "email")}
            {this.renderPasswordInput("password", "Password")}
            <div className="form-group d-flex">
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="admin"
                    value=""
                    checked={this.state.userType === "superadmin"}
                    onChange={(e) => {
                      const { value, checked } = e.target;
                      if (checked) {
                        this.setState({ userType: "superadmin" });
                      } else {
                        this.setState({ userType: "admin" });
                      }
                    }}
                  />
                  <span></span>
                </label>
              </div>
              <label htmlFor="admin">Master Admin</label>
            </div>
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  administrator: getAdministrator(state).administrator,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAdministrator: (params) => dispatch(loadAllAdministrator(params)),
  addAdministrator: (param, callback) =>
    dispatch(addAdministrator(param, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAdministrator);
