import React, { Component } from "react";
import { connect } from "react-redux";
import { loadTrainingAnalytics, getAssessment } from "../../store/assessment";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import Skeleton from "react-loading-skeleton";
import HelmetComponent from "../common/helmetComponent";
import Moment from "moment";

const initialState = {
  overallSuccessRate: "",
  successAttemptRate: "",
  userShow: false,
  asside: false,
  data: {
    title: "",
    description: "",
    answerType: "",
  },
  images: {},
  isMandatory: false,
  isPublished: false,
  isExternalTraining: false,
  delete_id: "",
  asside3: false,
  modal: false,
  modalQues: false,
  asside2: false,
  isShow: false,
  questionModal: false,
  photoStatus: "Add file",
  displayImage: "",
  editShow: false,
  id: "",
  editData: null,
  editing: false,
  errors: {},
  sectionId: "",
  questionId: "",
  editingQuestion: false,
  editDataQuestion: null,
  userData: "",
  loading: false,
  maxStar: 0,
  maxTrainingsAttended: 0,
};

export class TrainingAnalytics extends Component {
  state = initialState;

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.loadTrainingAnalytics(
      {
        page: "1",
        training: this.props.location.state && this.props.location.state.id,
      },
      () => this.setState({ loading: false })
    );
  };

  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  toggleDelQues = (sectionId, questionId) => {
    this.setState({
      modalQues: !this.state.modalQues,
      sectionId,
      questionId,
    });
  };

  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggleAddQuestion = (id) => {
    this.setState({
      sectionId: id,
      questionModal: !this.state.questionModal,
    });
  };

  toggleEditQuestion = (sectionId, questionId, data) => {
    this.setState({
      editingQuestion: true,
      editDataQuestion: data,
      sectionId,
      questionId,
      questionModal: !this.state.questionModal,
    });
  };

  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleEditUser = (e, data) => {
    if (e) e.preventDefault();
    this.setState({
      isShow: !this.state.isShow,
      editing: true,
      editData: data,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.loading && prevState.loading !== this.state.loading) {
      this.userDataEvaluations();
    }
  };

  userDataEvaluations = () => {
    var a = [];
    var b = [];
    var totalQuestions = 0;
    var correctQuestions = 0;

    this.props.tariningAnalytics &&
      this.props.tariningAnalytics.userEvaluations.map(
        (item) => (
          a.push(item.attempts[0].totalCorrectQuestions),
          b.push(item.attempts[0].totalQuestions)
        )
      );

    for (let i = 0; i < a.length; i++) {
      totalQuestions = totalQuestions + +b[i];
      correctQuestions = correctQuestions + +a[i];
    }

    var successAttemptRate = (
      parseFloat(correctQuestions / totalQuestions) * 100
    ).toFixed(2);

    var d = [];
    var e = [];
    var totalAttemptCorrectQuestions = 0;
    var totalAttemptQuestions = 0;

    var overallTrainingCompletedAttempts =
      this.props.tariningAnalytics &&
      this.props.tariningAnalytics.overallTrainingCompletedAttempts;
    this.props.tariningAnalytics &&
      this.props.tariningAnalytics.userEvaluations.map((item) =>
        item.attempts.map(
          (at) => (
            !at.isQuit && d.push(at.totalCorrectQuestions),
            e.push(at.totalQuestions)
          )
        )
      );

    for (let i = 0; i < overallTrainingCompletedAttempts; i++) {
      totalAttemptQuestions = totalAttemptQuestions + +e[i];
      totalAttemptCorrectQuestions = totalAttemptCorrectQuestions + +d[i];
    }

    const overallSuccessRate = (
      parseFloat(totalAttemptCorrectQuestions / totalAttemptQuestions) * 100
    ).toFixed(2);

    this.setState({ successAttemptRate, overallSuccessRate });
  };

  render() {
    const data = this.props.tariningAnalytics;

    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Training Analytics" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div
                  className="subheader py-3 py-lg-4 subheader-transparent"
                  id="kt_subheader"
                >
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h2
                          className="d-flex align-items-center text-dark font-weight-bold my-1 mr-3"
                          style={{ fontSize: "1.25rem", fontWeight: "500" }}
                        >
                          Training Analytics
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div
                      className="card card-custom card-sticky"
                      id="kt_page_sticky_card"
                    >
                      <div
                        className="card-header"
                        style={{
                          "z-index": "90",
                          top: "-1px",
                          left: "125px",
                          right: "25px",
                        }}
                      >
                        <div className="card-title col col-12">
                          {this.state.loading ? (
                            <>
                              <Skeleton width={250} />
                              <div
                                className="d-flex"
                                style={{
                                  border: "",
                                  display: "flex",
                                  marginLeft: "5rem",
                                }}
                              >
                                <div style={{ marginLeft: "7rem" }}>
                                  <Skeleton width={150} />
                                </div>

                                <div style={{ marginLeft: "1rem" }}>
                                  <Skeleton width={150} />
                                </div>
                                <div style={{ marginLeft: "1rem" }}>
                                  <Skeleton width={150} />
                                </div>

                                <div style={{ marginLeft: "1rem" }}>
                                  <Skeleton width={150} />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <h3
                                className="card-label title-margin-adjust"
                                style={{ width: "34%" }}
                              >
                                {data && data.name}
                                <i className="mr-2"></i>
                              </h3>
                              <div
                                style={{
                                  border: "",
                                  display: "flex",
                                }}
                              >
                                <h5>
                                  Number of Users Attempted :{" "}
                                  {data && data.numberOfUsersAttempted
                                    ? data.numberOfUsersAttempted
                                    : 0}
                                </h5>

                                <h5 style={{ marginLeft: "2rem" }}>
                                  Overall Training Attempts :{" "}
                                  {data && data.overallTrainingAttempts
                                    ? data.overallTrainingAttempts
                                    : 0}
                                </h5>

                                <h5 style={{ marginLeft: "2rem" }}>
                                  Overall Success Rate :{" "}
                                  {!isNaN(this.state.overallSuccessRate)
                                    ? this.state.overallSuccessRate + " "
                                    : 0}
                                  %
                                </h5>
                                <h5 style={{ marginLeft: "2rem" }}>
                                  First attempt success rate :{" "}
                                  {!isNaN(this.state.successAttemptRate)
                                    ? this.state.successAttemptRate + " "
                                    : 0}
                                  %
                                </h5>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div action="" id="training">
                          {this.state.loading
                            ? [1, 2].map((a) => (
                                <>
                                  <div className="d-flex flex-column row mb-4">
                                    {/* <div > */}
                                    <div className="d-flex  mb-4">
                                      <div style={{ marginLeft: "12px" }}>
                                        {" "}
                                        <Skeleton width={200} height={15} />
                                      </div>
                                      <div style={{ marginLeft: "50px" }}>
                                        <Skeleton width={200} height={15} />
                                      </div>
                                    </div>
                                  </div>
                                  {[1, 2, 3, 4].map((at, i) => (
                                    <>
                                      <div className="row">
                                        <div className="d-flex flex-column mb-5 ">
                                          <div className="d-flex col col-12">
                                            <div>
                                              <Skeleton
                                                width={200}
                                                height={15}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: "40px",
                                                marginRight: "230px",
                                              }}
                                            >
                                              <Skeleton
                                                width={350}
                                                height={15}
                                              />
                                            </div>
                                            <div>
                                              <Skeleton
                                                width={200}
                                                height={15}
                                              />
                                            </div>
                                            <div style={{ marginLeft: "40px" }}>
                                              <Skeleton
                                                width={350}
                                                height={15}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </>
                              ))
                            : data &&
                              data.userEvaluations &&
                              data.userEvaluations.length > 0
                            ? data &&
                              data.userEvaluations.map(
                                ({ attempts, createdBy }, i) => (
                                  <>
                                    <div className="d-flex mb-4 ">
                                      <div>
                                        {createdBy &&
                                        createdBy.fullName &&
                                        createdBy.fullName.length > 0 ? (
                                          <h6>Name : {createdBy.fullName}</h6>
                                        ) : createdBy &&
                                          createdBy.email &&
                                          createdBy.email.length > 0 ? (
                                          <h6>Email : {createdBy.email}</h6>
                                        ) : createdBy &&
                                          createdBy.mobile &&
                                          createdBy.mobile.length > 0 ? (
                                          <h6>Mobile : {createdBy.mobile}</h6>
                                        ) : (
                                          <h6>Name : --</h6>
                                        )}
                                      </div>
                                      <div style={{ marginLeft: "100px" }}>
                                        {createdBy &&
                                        createdBy.fullName &&
                                        createdBy.fullName.length > 0 &&
                                        createdBy.email &&
                                        createdBy.email.length > 0 ? (
                                          <h6> Email : {createdBy.email}</h6>
                                        ) : createdBy &&
                                          createdBy.email &&
                                          createdBy.email.length > 0 &&
                                          createdBy.mobile &&
                                          createdBy.mobile.length > 0 ? (
                                          <h6>Mobile : {createdBy.mobile}</h6>
                                        ) : createdBy &&
                                          createdBy.fullName &&
                                          createdBy.fullName.length > 0 &&
                                          createdBy.mobile &&
                                          createdBy.mobile.length > 0 ? (
                                          <h6>Mobile : {createdBy.mobile}</h6>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div style={{ marginLeft: "100px" }}>
                                        {createdBy &&
                                        createdBy.fullName &&
                                        createdBy.fullName.length > 0 &&
                                        createdBy.email &&
                                        createdBy.email.length > 0 &&
                                        createdBy.mobile ? (
                                          <h6>Mobile : {createdBy.mobile}</h6>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    {attempts && attempts.length > 0
                                      ? attempts.map((at, i) => (
                                          <div className="mb-4">
                                            <div className="row">
                                              <h6
                                                className="col col-12 mb-4"
                                                style={{ fontWeight: "600" }}
                                              >
                                                Attempt : {i + 1}
                                              </h6>
                                            </div>
                                            <div className="row">
                                              <h6 className="col col-2">
                                                {" "}
                                                Total Questions :
                                              </h6>
                                              <h6 className="col col-4 ">
                                                {at.totalQuestions
                                                  ? at.totalQuestions
                                                  : "--"}
                                              </h6>
                                              <h6 className="col col-2">
                                                Stars :
                                              </h6>
                                              <h6 className="col col-4">
                                                {at.starsCollected
                                                  ? at.starsCollected
                                                  : "0"}
                                              </h6>
                                            </div>
                                            <div className="row">
                                              <h6 className="col col-2">
                                                {" "}
                                                Correct Answers :
                                              </h6>
                                              <h6 className="col col-4">
                                                {at.totalCorrectQuestions
                                                  ? at.totalCorrectQuestions
                                                  : "--"}
                                              </h6>
                                              <h6 className="col col-2">
                                                Quit :
                                              </h6>
                                              <h6 className="col col-4">
                                                {at.isQuit === false
                                                  ? "No"
                                                  : "Yes"}
                                              </h6>
                                            </div>
                                            <div className="row">
                                              <h6 className="col col-2">
                                                {" "}
                                                Incorrect Answers :
                                              </h6>
                                              <h6 className="col col-4">
                                                {at.totalQuestions &&
                                                at.totalCorrectQuestions
                                                  ? at.totalQuestions -
                                                    at.totalCorrectQuestions
                                                  : "--"}
                                              </h6>
                                              <h6 className="col col-2">
                                                Date :
                                              </h6>
                                              <h6 className="col col-4">
                                                {" "}
                                                {at.createdAt &&
                                                  Moment(at.createdAt).format(
                                                    "LL"
                                                  )}
                                              </h6>
                                            </div>
                                            <div className="row">
                                              <h6 className="col col-2">
                                                {" "}
                                                % Marks :
                                              </h6>
                                              <h6 className="col col-4">
                                                {" "}
                                                {at.totalCorrectQuestions &&
                                                at.totalQuestions &&
                                                parseFloat(
                                                  (at.totalCorrectQuestions /
                                                    at.totalQuestions) *
                                                    100
                                                ) === 100
                                                  ? 100
                                                  : parseFloat(
                                                      (at.totalCorrectQuestions /
                                                        at.totalQuestions) *
                                                        100
                                                    ).toFixed(1)}
                                                %
                                              </h6>
                                            </div>
                                          </div>
                                        ))
                                      : "No Attempts for the particular User"}
                                    <hr />
                                  </>
                                )
                              )
                            : "No Records available for the particular training"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserProfile
          userShow={this.state.userShow}
          toggleShowUser={this.toggleShowUser}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tariningAnalytics: getAssessment(state).trainingAnalytics[0],
});

const mapDispatchToProps = (dispatch) => ({
  loadTrainingAnalytics: (params, callback) =>
    dispatch(loadTrainingAnalytics(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingAnalytics);
