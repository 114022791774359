import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import closeBtn from "../../include/media/close.svg";
import Skeleton from "react-loading-skeleton";

export class UsersInfo extends Component {
  render() {
    const data =
      this.props.trainingAnalytics &&
      this.props.trainingAnalytics.userEvaluations;

    const name =
      this.props.trainingAnalytics && this.props.trainingAnalytics.name;

    return (
      <>
        <div
          className={
            this.props.show
              ? "offcanvas offcanvas3 offcanvas-right p-10 offcanvas-on "
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0"> Users Attempted</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => this.props.onHide()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />

          {this.props.loading ? (
            <>
              <div className="mb-5">
                <h4 className="mb-4 text-bold-trainingName">
                  Training Details :
                </h4>
                <div>
                  <Skeleton width={150} />
                </div>

                <div className="mb-5"></div>

                <hr />
              </div>
              <div>
                <div class="d-flex align-items-center justify-content-between mt-4">
                  <div>
                    {[1, 2, 3, 4, 5, 6, 7].map(() => (
                      <>
                        <div className="mb-5 d-flex align-items-center justify-content-between">
                          <div className="square-logo-gray"></div>
                          <div>
                            <Skeleton width={200} />
                            <Skeleton width={200} />
                          </div>{" "}
                        </div>
                        <div className="mb-5"></div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : data && data.length > 0 ? (
            <>
              <div className="mb-5">
                <h4 className="mb-4 text-bold-trainingName">
                  Training Details :
                </h4>
                <div>
                  <h5>{name}</h5>
                </div>

                <div className="mb-5"></div>

                <hr />
              </div>

              <div>
                <div class="d-flex align-items-center justify-content-between mt-4">
                  <div>
                    {data.length > 0 &&
                      data.map((user) => (
                        <>
                          <div className="mb-5 d-flex align-items-center justify-content-between">
                            <div>
                              <h5>
                                {user.createdBy && user.createdBy.fullName}
                              </h5>
                              <h5>{user.createdBy && user.createdBy.mobile}</h5>
                            </div>{" "}
                          </div>
                          <div className="mb-5"></div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <h4>No data available for this particular training. </h4>
          )}
        </div>
      </>
    );
  }
}

export default UsersInfo;
