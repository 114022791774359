import React, { Component } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ModalPopup from "../common/modal";
import _ from "lodash";
import UsersInfo from "../common/usersInfoModal";

class AssessmentGrid extends Component {
  render() {
    return (
      <tbody className="datatable-body">
        {this.props.assessment.map((e, i) => (
          <tr
            data-row="10"
            className="datatable-row"
            style={{ left: "0px" }}
            key={i}
          >
            <td
              className="datatable-cell"
              style={{
                width: "3%",
                paddingRight: e.isMendatory ? "65px" : "0.3rem",
              }}
            >
              <span>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {!e.isMendatory && (
                    <div style={{ marginLeft: "20px" }}>
                      <div class="form-group mb-0">
                        <div class="radio-list">
                          <label class="radio" htmlFor={e._id}>
                            <input
                              id={e._id}
                              type="radio"
                              value={e._id}
                              name={e.name}
                              onChange={(event) => {
                                const { value, checked, name } = event.target;
                                let trainings = "";
                                let trainingsName = "";
                                if (checked) {
                                  trainings = value;
                                  trainingsName = name;
                                } else {
                                  trainings = "";
                                  trainingsName = "";
                                }
                                this.props.setTrainings(trainings);
                                this.props.setTrainingsName(trainingsName);
                                this.props.setAssignedUser(e.users);
                              }}
                              checked={
                                this.props.trainings === e._id ? true : false
                              }
                            />
                            <span></span>

                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </span>
            </td>
            <td className="datatable-cell" style={{ width: "25%" }}>
              <span
                className="d-flex align-items-center cursor-pointer"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/edit-trainings",
                    state: { data: e },
                  })
                }
              >
                {!_.isEmpty(e.displayPicture) && (
                  <img
                    style={{
                      width: "55px",
                      height: "55px",
                      borderRadius: "3px",
                    }}
                    src={
                      process.env.REACT_APP_MEDIAURL + e.displayPicture.small
                    }
                    alt=""
                  />
                )}
                <div
                  className={
                    e.sections && e.sections.length === 0
                      ? "font-weight-bolder font-size-lg mb-0 ml-3 asses-name"
                      : e.sections.map((sec) => {
                          return sec.questions.length === 0
                            ? "font-weight-bolder font-size-lg mb-0 ml-3 asses-name"
                            : "font-weight-bolder font-size-lg mb-0 ml-3";
                        })
                  }
                >
                  {e.name ? e.name : "--"}
                </div>
              </span>
            </td>
            <td className="datatable-cell" style={{ width: "8%" }}>
              {e.isMendatory ? (
                <span>
                  <div className="label label-lg font-weight-bold label-light-success label-inline">
                    Yes
                  </div>
                </span>
              ) : (
                <span>
                  <div className="label label-lg font-weight-bold label-light-blue label-inline">
                    No
                  </div>
                </span>
              )}
            </td>
            <td className="datatable-cell" style={{ width: "8%" }}>
              {e.isPublished ? (
                <span>
                  <div
                    className="label label-lg font-weight-bold label-light-success label-inline"
                    // onClick={() => this.props.handlePublished(e._id, false)}
                  >
                    Yes
                  </div>
                </span>
              ) : (
                <span>
                  <div
                    className="label label-lg font-weight-bold label-light-danger label-inline"
                    // onClick={() => this.props.handlePublished(e._id, true)}
                  >
                    No
                  </div>
                </span>
              )}
            </td>
            {/* <td className="datatable-cell" style={{ width: "10%" }}>
              {e.isExternalTraining ? (
                <span>
                  <div
                    className="label label-lg font-weight-bold label-light-success label-inline"
                    // onClick={() =>
                    //   this.props.handleExternalTraining(e._id, false)
                    // }
                  >
                    Yes
                  </div>
                </span>
              ) : (
                ""
                // <span>
                //   <div
                //     className="label label-lg font-weight-bold label-light-danger label-inline"
                //     // onClick={() =>
                //     //   this.props.handleExternalTraining(e._id, true)
                //     // }
                //   >
                //     No
                //   </div>
                // </span>
              )}
            </td> */}
            <td className="datatable-cell" style={{ width: "7%" }}>
              <span class="d-flex align-items-center">
                <div className="font-weight-bolder font-size-lg mb-0">
                  {e.users && e.users.length}
                </div>
              </span>
            </td>
            <td className="datatable-cell" style={{ width: "8%" }}>
              <span class="d-flex align-items-center">
                <div
                  className="font-weight-bolder font-size-lg mb-0 btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  onClick={() => this.props.toggleUsersAttendedShow(e._id)}
                >
                  {e && e.attemptedUserCount}
                </div>
              </span>
            </td>

            <td className="datatable-cell" style={{ width: "14%" }}>
              <span>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {!_.isEmpty(e.owner)
                    ? e.owner.firstName + " " + e.owner.lastName
                    : "--"}
                </div>
              </span>
            </td>
            <td className="datatable-cell" style={{ width: "8%" }}>
              <span>
                <div className="font-weight-bolder font-size-lg mb-0">
                  {Moment(e.createdAt).format("DD-MM-YYYY")}
                </div>
              </span>
            </td>
            <td
              data-field="Actions"
              data-autohide-disabled="false"
              aria-label="null"
              className="datatable-cell"
              style={{ width: "12%" }}
            >
              <span
                style={{
                  overflow: "visible",
                  position: "relative",
                }}
              >
                <Link
                  to={{ pathname: "/edit-trainings", state: { data: e } }}
                  className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  title="Edit details"
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                        ></path>
                        <path
                          d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </Link>
                {/* {e.users && e.users.length > 0 && (
                  <a
                    href="javascript:void (0)"
                    className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                    title="Assigned users"
                    onClick={(ev) =>
                      this.props.toggleAssignedFlyer(ev, e.users, e._id)
                    }
                  >
                    <span className="svg-icon svg-icon-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                            style={{ fill: "#b5b5c3" }}
                          ></path>
                          <path
                            d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                            fill="#b5b5c3"
                            style={{ fill: "#b5b5c3" }}
                          ></path>
                        </g>
                      </svg>
                    </span>{" "}
                  </a>
                )} */}
                <a
                  href="javascript:void (0)"
                  onClick={() => this.props.toggle(e._id)}
                  className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  title="Delete"
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000"
                          fillRule="nonzero"
                        ></path>
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </a>
                <Link
                  to={{ pathname: "/training-analytics", state: { id: e._id } }}
                  style={{ color: "rgb(0,75,145)" }}
                  className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                          style={{ fill: "#b5b5c3" }}
                        ></path>
                        <path
                          d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                          fill="#b5b5c3"
                          style={{ fill: "#b5b5c3" }}
                        ></path>
                      </g>
                    </svg>
                  </span>
                </Link>
              </span>
            </td>
          </tr>
        ))}
        <ModalPopup
          show={this.props.modal}
          onHide={this.props.toggle}
          delete_id={this.props.delete_id}
          deleted={this.props.deleted}
        />

        <UsersInfo
          show={this.props.usersAttendedShow}
          onHide={this.props.toggleUsersAttendedShow}
          id={this.props.userAttendedId}
          loading={this.props.loading}
          trainingAnalytics={this.props.trainingAnalytics}
        />
      </tbody>
    );
  }
}
export default AssessmentGrid;
