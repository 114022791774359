import React, { Component } from "react";
import HelmetComponent from "../common/helmetComponent";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import { getAdministrator, loadAnalytics } from "../../store/administrator";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const initialState = {
  userShow: false,
  asside: false,
  data: {
    title: "",
    description: "",
    answerType: "",
  },
  images: {},
  isMandatory: false,
  isPublished: false,
  isExternalTraining: false,
  delete_id: "",
  asside3: false,
  modal: false,
  modalQues: false,
  asside2: false,
  isShow: false,
  questionModal: false,
  photoStatus: "Add file",
  displayImage: "",
  editShow: false,
  id: "",
  editData: null,
  editing: false,
  errors: {},
  sectionId: "",
  questionId: "",
  editingQuestion: false,
  editDataQuestion: null,
  userData: "",
  // loading: false,
  maxStar: 0,
  maxTrainingsAttended: 0,
};

export class Dashboard extends Component {
  state = initialState;

  componentDidMount = () => {
    this.props.loadAnalytics();
  };

  toggleAsside = () => {
    this.setState({
      asside2: false,
      asside3: false,
      asside: !this.state.asside,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  toggleDelQues = (sectionId, questionId) => {
    this.setState({
      modalQues: !this.state.modalQues,
      sectionId,
      questionId,
    });
  };

  toggleAsside2 = () => {
    this.setState({
      asside: false,
      asside3: false,
      asside2: !this.state.asside2,
    });
  };
  toggleAsside3 = () => {
    this.setState({
      asside2: false,
      asside3: !this.state.asside3,
      asside: false,
    });
  };
  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };

  toggleAddQuestion = (id) => {
    this.setState({
      sectionId: id,
      questionModal: !this.state.questionModal,
    });
  };

  toggleEditQuestion = (sectionId, questionId, data) => {
    this.setState({
      editingQuestion: true,
      editDataQuestion: data,
      sectionId,
      questionId,
      questionModal: !this.state.questionModal,
    });
  };

  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleEditUser = (e, data) => {
    if (e) e.preventDefault();
    this.setState({
      isShow: !this.state.isShow,
      editing: true,
      editData: data,
    });
  };

  render() {
    const data =
      this.props.overallAnalytics &&
      this.props.overallAnalytics.overallAnalytics;

    const loading =
      this.props.overallAnalytics && this.props.overallAnalytics.loading;

    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 === true ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader enabled aside-enabled aside-fixed aside-secondary-enabled aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Dashboard" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />

            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div
                  className="subheader py-3 py-lg-4 subheader-transparent"
                  id="kt_subheader"
                >
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center mr-1">
                      <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h2
                          className="d-flex align-items-center text-dark font-weight-bold my-1 mr-3"
                          style={{ fontSize: "1.25rem", fontWeight: "500" }}
                        >
                          Dashboard
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div
                      className="card card-custom card-sticky"
                      id="kt_page_sticky_card"
                    >
                      <div
                        className="card-header"
                        style={{
                          "z-index": "90",
                          top: "-1px",
                          left: "125px",
                          right: "25px",
                        }}
                      >
                        <div className="card-title">
                          <h3 className="card-label">
                            Dashboard
                            <i className="mr-2"></i>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        {loading ? (
                          <>
                            <div className="row mb-5">
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>

                              <div className="col col-4">
                                <Skeleton width={250} />
                              </div>
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>
                              <div className="col col-3">
                                <Skeleton width={250} />
                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>

                              <div className="col col-4">
                                <Skeleton width={250} />
                              </div>
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>
                              <div className="col col-3">
                                <Skeleton width={250} />
                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>

                              <div className="col col-4">
                                <Skeleton width={250} />
                              </div>
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>
                              <div className="col col-3">
                                <Skeleton width={250} />
                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>

                              <div className="col col-4">
                                <Skeleton width={250} />
                              </div>
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>
                              <div className="col col-3">
                                <Skeleton width={250} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col col-2">
                                <Skeleton width={150} />
                              </div>

                              <div className="col col-4">
                                <Skeleton width={250} />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row mb-5">
                              <h6 className="col col-3">
                                Number of Client App Users :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.clientAppUserss
                                  ? data.clientAppUserss
                                  : "--"}
                              </h6>
                              <h6 className="col col-3">
                                Number of Reward Requests :
                              </h6>
                              <h6 className="col col-3">
                                {data && data.totalRequests
                                  ? data.totalRequests
                                  : "--"}
                              </h6>
                            </div>
                            <div className="row mb-5">
                              <h6 className="col col-3">
                                Number of Active Trainings :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.trainings ? data.trainings : "--"}
                              </h6>
                              <h6 className="col col-3">
                                Number of Pending Reward Requests :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.pendingRequests
                                  ? data.pendingRequests
                                  : "--"}
                              </h6>
                            </div>
                            <div className="row mb-5">
                              {" "}
                              <h6 className="col col-3">
                                Number of Times Trainings Attended :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.trainingAttempts
                                  ? data.trainingAttempts
                                  : "--"}
                              </h6>
                              <h6 className="col col-3">
                                {" "}
                                Number of Approved Reward Requests :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.approvedRequests
                                  ? data.approvedRequests
                                  : "--"}
                              </h6>
                            </div>
                            <div className="row mb-5">
                              <h6 className="col col-3">
                                Number of Users Attended Trainings :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.trainingAttendedUsers
                                  ? data.trainingAttendedUsers
                                  : "--"}
                              </h6>
                              <h6 className="col col-3">
                                Number of Rejected Reward Requests :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.rejectedRequests
                                  ? data.rejectedRequests
                                  : "--"}
                              </h6>
                            </div>
                            <div className="row mb-5">
                              <h6 className="col col-3">
                                Number of Reward Products :
                              </h6>
                              <h6 className="col col-2">
                                {data && data.totalProducts
                                  ? data.totalProducts
                                  : "--"}
                              </h6>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserProfile
          userShow={this.state.userShow}
          toggleShowUser={this.toggleShowUser}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  overallAnalytics: getAdministrator(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadAnalytics: (callback) => dispatch(loadAnalytics(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
