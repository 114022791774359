import React from "react";
import "./include/css/style.bundle.css";
import "./include/css/alert.css";
import "./include/css/pages/login/classic/login-2.css";
import "./include/plugins/global/plugins.bundle.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./components/administrator/signIn";
import AdminTable from "./components/administrator/adminTable";
import Assessment from "./components/assessment/assessment";
import AssessmentForm from "./components/assessment/assessmentForm";
import orgTable from "./components/organisations/orgTable";
import ShopTable from "./components/shop/shopTable";
import RequestTable from "./components/productRequest/requestTable";
import userAnalytics from "./components/organisations/userAnalytics";
import Dashboard from "./components/administrator/dashboard";
import TrainingAnalytics from "./components/assessment/assessmentAnalytics";

const store = configureStore();

function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          hideProgressBar
          autoClose={5000}
          closeOnClick
          pauseOnFocusLoss
          toastClassName="alert alert-success alert-white"
        />
        <Router>
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/administrators" component={AdminTable} />
            <Route exact path="/users" component={orgTable} />
            <Route exact path="/trainings" component={Assessment} />
            <Route exact path="/edit-trainings" component={AssessmentForm} />
            <Route exact path="/shop" component={ShopTable} />
            <Route exact path="/requests" component={RequestTable} />
            <Route exact path="/user-analytics" component={userAnalytics} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route
              exact
              path="/training-analytics"
              component={TrainingAnalytics}
            />
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
