import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  informationSaved,
  failed,
} from "../common/misc";
import {
  loadAllAdministrator,
  updateAdministrator,
  getAdministrator,
  resetPassword,
} from "../../store/administrator";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { Link } from "react-router-dom";

const initialState = {
  data: {
    password: "",
  },
  errors: {
    password: "",
  },
};

class ChangePassword extends Form {
  state = initialState;
  schema = {
    password: Joi.string()
      .min(8)
      .max(16)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_.+-])[A-Za-z\d@#$!%*?&_.+-]{6,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          if (err.type === "string.regex.name") {
            if (err.context.value === "") {
              err.message = "Password is required.";
            } else {
              err.message =
                "Password should be between 8 - 16 characters long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
            }
          }
          switch (err.type) {
            case "any.required":
              err.message = "Password required";
              break;

            case "any.empty":
              err.message = "Password required";
              break;

            case "string.min":
              err.message =
                "Password should be between 8 - 16 characters long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
              break;

            case "string.max":
              err.message =
                "Password should be between 8 - 16 characters long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
              break;
            default:
          }
        });
        return errors;
      }),
  };
  doSubmit = () => {
    const { password } = this.state.data;
    const id = this.props.change_id;
    var formData = {
      id,
      newPassword: password,
    };
    this.props.resetPassword(formData, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAdministrator({ page: this.props.initialPage });
      this.props.toggleChangePassword();
      //toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  toggleClose = () => {
    this.setState(initialState);
    this.props.toggleChangePassword();
  };

  render() {
    return (
      <>
        <div
          className={
            this.props.changePass === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on"
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Change Password</h3>
            <a
              href="#/"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleClose}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderPasswordInput("password", "Password")}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <Link to={"#"} className="font-weight-bold"></Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.changePass === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  administrator: getAdministrator(state).administrator,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAdministrator: (params) => dispatch(loadAllAdministrator(params)),
  resetPassword: (data, callback) => dispatch(resetPassword(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
