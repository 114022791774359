import React from "react";

const AlertError = (props) => {
  return (
    <>
      <span className="alert-icon" style={{ marginTop: "-2px" }}>
        <i
          className="fas fa-exclamation-circle alert-red"
          aria-hidden="true"
        ></i>
      </span>
      {props.message}
    </>
  );
};

export default AlertError;
