import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "misc",
  initialState: {
    country: [],
    admin: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    countryRequested: (country, action) => {
      country.loading = true;
    },
    countryReceived: (country, action) => {
      country.country = action.payload.data;
      country.loading = false;
      country.lastFetch = Date.now();
    },
    countryRequestFailed: (country, action) => {
      country.loading = false;
    },
  },
});

export const { countryRequested, countryReceived, countryRequestFailed } =
  slice.actions;
export default slice.reducer;

// Action Creators
const url = "countries";

export const loadCountry = (params) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: countryRequested.type,
      onSuccess: countryReceived.type,
      onError: countryRequestFailed.type,
    })
  );
};

export const markAsCompleted = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: `organizations/markAsCompleted/${id}`,
      method: "post",
    })
  );
};

export const getMisc = createSelector(
  (state) => state.entities.misc,
  (misc) => misc
);
